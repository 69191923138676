// Copyright (C) 2018-2024 Skylark Drones

export { SVG_ICONS, PROJECT_TYPE_LOGOS } from './icon-paths'

export const LATEST_DMO_RELEASE = process.env.VUE_APP_RELEASE_VERSION

export const BASE_API_HOST_URL = process.env.VUE_APP_BASE_API_HOST_URL

export const APP_VERSIONS_MIN_THRESHOLD_PERCENT = parseFloat(
  process.env.VUE_APP_APP_VERSIONS_MIN_THRESHOLD_PERCENT
)

export const MAX_MRU_CAMERAS = 5

export const DEV_TOOLS = process.env.VUE_APP_DEV_TOOLS === 'true'

export const NEW_STREAM_SCREEN =
  process.env.VUE_APP_NEW_STREAM_SCREEN === 'true'
