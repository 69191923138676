// Copyright (C) 2024 Skylark Drones

import { UnitConverter } from './UnitConverter'
import store from '@/store'

class TemperatureUnitConverter extends UnitConverter {
  storeState = store.state.generic

  static Units = {
    celsius: 'celsius',
    fahrenheit: 'fahrenheit'
  }

  mapResponse = {
    celsius: { shortUnit: '°C', longUnit: 'Celsius' },
    fahrenheit: { shortUnit: '°F', longUnit: 'Fahrenheit' }
  }

  constructor() {
    super()

    this.toPreferredUnit = {
      [TemperatureUnitConverter.Units.celsius]: value => value,
      [TemperatureUnitConverter.Units.fahrenheit]: value => value * (9 / 5) + 32
    }
  }

  /**
   * Converts a value from Celsius (base unit) to the user's preferred temperature unit.
   * @param {number} value The numerical value to be converted.
   * @param {string} baseUnit The base unit of temperature (default is Celsius).
   * @param {string} outputUnit The preferred output unit.
   * @returns {Object} An object with 'value', 'shortUnit', and 'longUnit'.
   */
  convert(
    value,
    baseUnit = TemperatureUnitConverter.Units.celsius,
    outputUnit = this.storeState.user?.unit_preferences?.temperature
  ) {
    const preferredTemperatureUnit = outputUnit ?? baseUnit
    return {
      value: this.toPreferredUnit[preferredTemperatureUnit](value),
      shortUnit: this.mapResponse[preferredTemperatureUnit].shortUnit,
      longUnit: this.mapResponse[preferredTemperatureUnit].longUnit
    }
  }
}

export default new TemperatureUnitConverter()
