// Copyright (C) 2020-2024 Skylark Drones

const BASE_PATH =
  'https://dmo-public-assets.s3.ap-south-1.amazonaws.com/releases'

export const releases = [
  {
    version: '2025.1',
    caption: 'Go Live, Supercharged',
    product: 'DMO Web Portal & Android App',
    date: '2025-01-16',
    announcements: [
      {
        message:
          'Happy New Year 2025 from the DMO Team! Kick off the year with ' +
          'excitement and innovation — introducing the all-new, more powerful ' +
          'DMO Go Live. Take flight and explore what is new today!',
        icon: 'mdi-party-popper',
        color: 'green'
      }
    ],
    features: [
      {
        title: 'Revamped Live Stream Viewer Experience',
        description:
          'The live stream viewer has been completely redesigned from the ground up ' +
          'to deliver a vastly improved experience. The new design provides a clean ' +
          'streamlined interface and surfaces powerful features such as AI Object ' +
          'Detection, Camera Controls and more. Read on to discover all the exciting ' +
          'new DMO Go Live updates!',
        image: {
          src: `${BASE_PATH}/revamed-live-stream-viewer.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Live Stream - Camera Controls',
        description:
          'DMO Go Live has always been a game-changer with its 2-way communication, ' +
          'making collaboration easier than ever. Now, we’re taking it to the next ' +
          'level with Camera Controls, allowing live stream viewers to control the ' +
          'camera — without compromising drone safety. As the pilot, you remain in full ' +
          'control, with the ability to see who’s controlling the camera and disable ' +
          'remote access anytime you choose.' +
          '<br><br><b>Note</b>: Camera Controls are only available to members of the ' +
          "pilot's organization.",
        image: {
          src: `${BASE_PATH}/camera-controls-full.png`,
          height: 300,
          xsHeight: 200
        }
      },
      {
        title: 'Live Stream - AI Object Detection Count',
        description:
          'AI Object Detection now displays object counts, making it easier than ' +
          'ever to quickly see details like the number of vehicles on the road, ' +
          'crowd sizes, and more!',
        image: {
          src: `${BASE_PATH}/objection-detection-count.png`,
          height: 300,
          xsHeight: 200
        }
      },
      {
        title: 'Live Stream - Situational Awareness',
        description:
          'To enhance situational awareness for live stream viewers, DMO Go Live ' +
          "now displays key details such as the drone's battery percentage, " +
          'hardware error alerts, and current weather conditions at the site. ' +
          'This helps viewers better understand actions like sudden landings or ' +
          'unexpected maneuvers made by the pilot.',
        image: {
          src: `${BASE_PATH}/drone-state-card-expanded.png`,
          height: 300,
          xsHeight: 200
        }
      }
    ],
    improvements: [
      {
        title: 'New Mission Progress Sync Indicator',
        description:
          'The mission progress sync status is now displayed on both the home ' +
          'page and the site details page, keeping pilots informed about any ' +
          'pending mission syncs. This serves as a gentle reminder to connect ' +
          'to the internet when needed. Plus, enjoy a more reliable and seamless ' +
          'mission sync experience than ever before!'
      },
      {
        title: 'Polished Pilot Livestream Experience',
        description:
          '<ul>' +
          '<li>Improved Live Status indication to keep the pilot informed</li>' +
          '<li>QR screen for sharing stream link has been refined</li>' +
          '</ul>'
      },
      {
        title: 'Mission Draw Polishes',
        description:
          '<ul>' +
          '<li>' +
          'Hide the mission sidebar to get a bigger drawing area while creating ' +
          'a mission' +
          '</li>' +
          '<li>' +
          'Mission characterisitics (flying time, battery count) is hidden until ' +
          'geometry is fully drawn' +
          '</li>' +
          '<li>' +
          'Surface important mission parameters such as GSD, Overlap at the top ' +
          'instead of hiding in the advanced section.' +
          '</li>' +
          '</ul>'
      }
    ],
    bugs: [
      {
        title: 'Mission Generation Failure Due to DJI Error',
        description:
          'A few mission generation failures were noticed this past month. These ' +
          'were caused due to edge cases where waypoints could not be generated ' +
          'due to DJI errors. We have been able to successfully investigate and' +
          'fix them.'
      },
      {
        title: 'Site With Complex Boundary Fails to Upload',
        description:
          'Due to geometrical operation failures, sites with complex boundaries ' +
          'would fail to upload. This edge case has been handled in this update.'
      },
      {
        title: 'Outdated Vision Sensor After Battery Swap',
        description:
          'It was noticed that the vision sensor status shown in the radar widget ' +
          'would become stale in the free flight screen after a battery swap. This ' +
          'was purely a visual bug. The sensor was always on. This visual bug has ' +
          'been fixed on priority to ease the minds of the pilots.'
      },
      {
        title: 'Preflight Checks Occasionally Stuck in Pending State',
        description:
          'The preflight checks shown before an automated mission execution would ' +
          'occasionally be stuck in a pending state due to incorrect initialization of ' +
          'certain comprehensive checks. This bug has been squashed finally.'
      },
      {
        title: 'Livestream Start Timeout',
        description:
          'Live streaming would fail to start if the network connectivity was poor. ' +
          'The start condition has been relaxed to improve the chances of a ' +
          'successful start.'
      },
      {
        title: 'Livestream Automatically Ends After 10 seconds of Poor Network',
        description:
          'The Livestream would aggressively end after 10 seconds of bad network. ' +
          'This rule has been relaxed to 90 seconds to reduce the changes of ' +
          'the livestream ending accidentally.'
      }
    ],
    faqs: []
  },
  {
    version: '2024.10',
    caption: 'Android App Bonanza',
    product: 'DMO Android App',
    date: '2024-10-20',
    features: [],
    improvements: [
      {
        title: 'Core Mission Workflow Upgrades on Android App',
        description:
          '<ul>' +
          '<li>' +
          "1 click mission split to fine-tune their size within DMO's renowned " +
          'mission autogeneration workflow to suit your operational flow.' +
          '</li>' +
          '<li>' +
          'Support for drawing, editing and deleting area mapping, corridor mapping ' +
          'and corridor video missions. This is supported even on sites with ' +
          'custom elevation with appropriate safeguards in place.' +
          '</li>' +
          '</ul>'
      },
      {
        title: 'Android App Technology Upgrades',
        description:
          'Key technologies powering the DMO Android app such as DJI SDK, Kotlin ' +
          'have been upgraded, and brings support for new drone models and bug ' +
          'fixes across the board. We have also laid the foundation to support ' +
          'Android 14.'
      },
      {
        title: 'Set User Profile Picture on Android',
        description:
          'Jazz up your user profile and make it humane with a nice profile picture ' +
          'unless you are a sentient being. Why? The top 3 pilots are highlighted' +
          "by us every month. Don't you want to see your own picture there?",
        image: {
          src: `${BASE_PATH}/top-pilots.png`,
          height: 500,
          xsHeight: 200
        }
      }
    ],
    bugs: [
      {
        title: 'Pilot Unable to Start Multiple Livestreams',
        description:
          'Starting a second or third livestream within the same app session used ' +
          'to fail with an indeterminate loader. This was not an intentional limit, ' +
          'but rather an inadvertent bug that has been squashed.'
      },
      {
        title: 'POI Mission Drone Capability Incorrectly Computed',
        description:
          "Each drone's capabilities and DMO compatibilities are computed to enable " +
          'or disable features on the fly. For instance, mission execution is ' +
          'disabled on drones that do not support waypoint missions. Along these ' +
          'lines, the POI mission capability was being computed incorrectly until ' +
          'now.'
      },
      {
        title: 'Incorrect Toggle Mission & GCP States',
        description:
          'The map layers tool was getting confused about whether the missions and ' +
          'gcps were being shown on the map or not. This confusion was been clarified. '
      },
      {
        title: 'Silent App Crash on Opening App in Background',
        description:
          'There was a silent behind the scenes crash that users never noticed but ' +
          'was happening. A crash cannot hide from developers however silent they ' +
          'are. This has been also been fixed in this release.'
      },
      {
        title: 'App Crash in Mission Generation Loading Dialog',
        description:
          'There was a rare app crash that occurred while waiting for the mission ' +
          'autogeneration to complete. This was rare as it only occurred if the user ' +
          'happened to perform actions like rotate the screen, or switch apps during ' +
          'that 2-3 seconds where missions were being created. Nonetheless we expect ' +
          'perfection, and went ahead with squashing this bug.'
      }
    ],
    faqs: []
  },
  {
    version: '2024.9',
    caption: 'Welcome POI Missions and Real-Time Tracking',
    product: 'DMO Web Portal & Android App',
    date: '2024-09-09',
    features: [
      {
        title: 'Targeted POI Mission Planning',
        description:
          'Take mission precision to the next level! You can now effortlessly ' +
          "plan missions around key Points of Interest (POIs). Whether it's capturing " +
          "the city's tallest towers or focusing on vital inspection spots, get ready " +
          'to plan with pinpoint accuracy by having complete control over the gimbal ' +
          'angle, orbit radius, image capture interval and more.',
        image: {
          src: `${BASE_PATH}/poi-mission.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Live Stream Real-Time Upgrades',
        description:
          'Eyes on the drone! Livestream viewers can now track your drone’s real-time ' +
          'location. This will help them get a better context of the livestream video. ' +
          'Watch this space as we add more real-time information and 2-way commands ' +
          'in the upcoming updates!',
        image: {
          src: `${BASE_PATH}/live-drone-location.png`,
          height: 400,
          xsHeight: 200
        }
      }
    ],
    improvements: [
      {
        title: 'Mission Size Limit Boosted (7x Larger!)',
        description:
          "Go big or go home! We've cranked up the mission size limit upto a " +
          'massive 5000 acres—seven times bigger than before. Perfect for those ' +
          'large-scale operations!'
      },
      {
        title: 'DMO Android Gets Smoother and Smarter',
        description:
          '<ul>' +
          '<li>' +
          '<b>Effortless GCP Export:</b> Export Ground Control Points in just a ' +
          'few taps!' +
          '</li>' +
          '<li>' +
          '<b>Detailed Drone Insights:</b> Access comprehensive drone specs and ' +
          'capabilities with ease.' +
          '</li>' +
          '<li><b>Site Filtering Upgraded:</b> Quickly find and filter sites with ' +
          'our improved options.</li>' +
          '</ul>'
      }
    ],
    bugs: [
      {
        title: 'Consistent App Experience',
        description:
          'Fixed an issue that caused the app to misbehave after being idle. Now, ' +
          'it’s steady and reliable when you come back!'
      },
      {
        title: 'Seamless Mission Generation for Small Corridors',
        description:
          "Squeezing through tight spots is no longer a problem! We've tackled the " +
          'bug that caused mission generation to freeze for small corridor missions. ' +
          'Smooth sailing from here on!'
      }
    ],
    faqs: []
  },
  {
    version: '2024.8',
    caption: '',
    product: 'DMO Web Portal & Android App',
    date: '2024-08-12',
    features: [
      {
        title: 'More Customisable Mission Planning',
        description:
          'Quickly generate missions for large areas, now with more control! You ' +
          'can now fine-tune the size of specific missions using the new vertical ' +
          'and horizontal split options. Give it a try, and easily undo any changes ' +
          'as needed.',
        image: {
          src: `${BASE_PATH}/mission-split.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Fly or No-Fly',
        description:
          'The DMO Android app now highlights airspace advisories front and center, ' +
          "letting you quickly check if it's safe to fly. This simple step can help " +
          'you avoid potential issues. Combined with integrated weather checks, ' +
          'DMO Android is the perfect co-pilot for every drone flight.',
        image: {
          src: `${BASE_PATH}/advisory-card-mobile.webp`,
          height: 400,
          xsHeight: 200
        }
      }
    ],
    improvements: [
      {
        title: 'DMO Android Polishes',
        description:
          '<ul>' +
          '<li>' +
          '<b>Grouped Settings</b>: With more settings being added, the settings are ' +
          'now grouped to make them easier to find at a glance.' +
          '</li>' +
          '<li>' +
          '<b>Active Indicators</b>: Gimbal Pitch, drone heading and course angle ' +
          'mission parameters show active feedback using icons that visualize the ' +
          'angle.' +
          '</li>' +
          '<li>' +
          '<b>Site Indicators</b>: Sample and shared sites are indicated using badges ' +
          'to quickly identify them from other sites.' +
          '</li>' +
          '<li>' +
          '<b>Incomplete Profile Nudge</b>: Got an incomplete profile? You will now ' +
          'see a nudge to fill it.' +
          '</li>' +
          '<li>' +
          '<b>Bigger Click Area</b>: The quick action option to create a site shown' +
          ' in the floating button sport a bigger click area to prevent misclicks.' +
          '</li>',
        image: {
          src: `${BASE_PATH}/grouped-settings.webp`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Enhanced Data Check',
        description:
          '<ul>' +
          '<li>' +
          'Added support for 10 new camera models: M30, M3D, X4S, X5S, X5, Mini 3, ' +
          'Mini SE, Zenmuse H20, H20N, Zenmuse H30.' +
          '</li>' +
          '<li>' +
          'Improved image boundary detection for more accurate overlap checks, ' +
          'reducing false negatives.' +
          '</li>' +
          '<li>' +
          'Verification of thermal images from cameras like the M3T, M30T, ' +
          'Zenmuse H20T are now supported!' +
          '</li>'
      },
      {
        title: 'Redesigned App Update Workflow',
        description:
          'With more drone controllers running custom Android builds without Google ' +
          'services, the DMO app’s update process is now more crucial than ever. ' +
          'That’s why we’ve focused this release on refining and improving the ' +
          'update workflow. You can moving forward also check for updates manually. '
      }
    ],
    bugs: [],
    faqs: []
  },
  {
    version: '2024.7',
    caption: 'Polish & Papercuts',
    product: 'DMO Web Portal & Android App',
    date: '2024-07-16',
    features: [],
    improvements: [
      {
        title: 'DMO Android Polishes Galore',
        description:
          '<ul>' +
          '<li>' +
          'Pilots can now check if the current conditions are suitable for flying ' +
          'at a glance using the weather card.' +
          '</li>' +
          '<li>Optimized sites card size for the DJI RC screen</li>' +
          '<li>' +
          'Critical mission parameters such as GSD, Overlap, Drone Speed, End ' +
          'of Mission action etc. are  now shown in the mission execution screen ' +
          'for quick reference.' +
          '</li>' +
          '<li>' +
          'Remaining mission flying time is also shown to help the pilot be better ' +
          'prepared during mission execution.' +
          '</li>' +
          '<li>' +
          'Consistent button styles, and several other design polishes across the ' +
          'mission execution screen to improve the pilot flying experience.' +
          '</li>' +
          '<li>' +
          'A new tips card has been added to the homepage to help pilots discover ' +
          'new tricks and features.' +
          '</li>' +
          '<li>' +
          'Downloaded KML files can be opened directly on the DMO Android app for ' +
          'quick site creation!' +
          '</li>' +
          '</ul>',
        image: {
          src: `${BASE_PATH}/revamped-exec-screen.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'More Intuitive Custom Elevation Error Messages',
        description:
          'When uploading custom elevations for a site, any errors encountered ' +
          'are better explained to the user. For example, if the uploaded custom ' +
          'elevations do not cover the entire site, a map is shown to help the ' +
          'user understand the core issue.',
        image: {
          src: `${BASE_PATH}/custom-error-elev-dialog-cropped.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Streamline Mission Data Check',
        description:
          'The data verification of missions have been further streamlined in this ' +
          'release by allowing selection of missions directly from the ' +
          'map or the mission sidebar. That should make it much easier for pilots ' +
          'to verify missions by identifying them geo-spatially rather than by name. ' +
          'That is not all. There are a few other mission data check polishes bundled ' +
          'in this release.'
      },
      {
        title: 'Use Different Slider Step Size For Different Units',
        description:
          'A minor but a welcomed usability improvement to the mission sliders which now ' +
          'uses custom step sizes suited to the selected units of measure. For ' +
          'example, when using square kilometer, the step size would be 0.01, while ' +
          'for acres, it would be 1 and so on.'
      },
      {
        title: 'Distinguish Detected Object Colors Better',
        description:
          'The colors of the boxes drawn around identified objects such as cars, ' +
          'trucks, persons in the DMO live stream are now more distinct for easier ' +
          'recognition.'
      }
    ],
    bugs: [
      {
        title: 'Corrupted/Damaged Images Stall Data Check',
        description:
          'Previously, if the user uploaded images contained any corrupted or ' +
          'damaged images, the entire data check process would stall. This situation ' +
          'has been improved by making the data check more robust by ignoring those ' +
          'few broken images, informing the user about it and continuing with ' +
          'verifying the rest of the images.'
      },
      {
        title: 'Corridor Mapping Mission Data Check Broken',
        description:
          'Data verification of corridor mapping missions was broken due to ' +
          'incorrect format of the mission geometry being used. This bug has been ' +
          'put to rest in this release.'
      },
      {
        title: 'Unsupported Mission Types Shown in Data Check',
        description:
          'The site data check workflow included incompatible mission types such as ' +
          'corridor video which is not supported by the data check feature. These ' +
          'mission types are now filtered out to avoid confusions.'
      }
    ],
    faqs: []
  },
  {
    version: '2024.6',
    caption: '',
    product: 'DMO Web Portal & Android App',
    date: '2024-06-09',
    features: [
      {
        title: 'Brand New Website',
        description:
          'We are excited to unveil the redesigned Drone Mission Ops ' +
          '<a style="color: #f05b28" href="https://dronemissionops.com">Website</a>! ' +
          'The fresh new look is designed to enhance your browsing experience with a ' +
          'clean and modern interface. Visit the new Drone Mission Ops website ' +
          'today to explore these exciting updates and more.',
        image: {
          src: `${BASE_PATH}/website-mvp.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'Customize Units of Measure',
        description:
          'You can now change the units of measure to suit your preference. ' +
          'Easily switch between imperial and metric units with just a few clicks. ' +
          'Whether you prefer working with hectares or feet, this update should ' +
          'make that possible!',
        image: {
          src: `${BASE_PATH}/units-of-measure-mvp.png`,
          height: 300,
          xsHeight: 200
        }
      },
      {
        title: 'Data Check Integrated In Site Workflow',
        description:
          'The Data Check feature, which was a huge success with pilots verifying ' +
          'over 700,000 images, is now even better. In this update, we have ' +
          'integrated Data Check into the core site workflow: Plan -> Fly -> Verify. ' +
          'This integration makes it easier than ever to ensure your images ' +
          'are accurate and reliable. Expect more updates to make this integration ' +
          'more seamless in the upcoming releases.',
        image: {
          src: `${BASE_PATH}/site-data-check-mvp.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'DMO White Label For Enterprises',
        description:
          'Enterprises using DMO can now request a white-labeled version for ' +
          'internal use. This allows companies to customize the platform with their ' +
          'own branding, providing a seamless experience for their teams.'
      }
    ],
    improvements: [
      {
        title: 'Drone Flying Screen Design Refresh',
        description:
          'The drone flying screen has undergone some design updates to improve ' +
          'the pilot experience. The buttons have improved contrast and are consistent. ' +
          'The camera parameters island containing ISO, Shutter Speed etc. has also been ' +
          'refined to be compact and out of the way.'
      },
      {
        title: 'Persistent Homepage Site Filter',
        description:
          'Quick site filters found in the homepage are now persistent across ' +
          'sessions and provide seamless access to sites of interest.'
      },
      {
        title: 'Various Data Check Polishes',
        description:
          '<ul>' +
          '<li>View camera info in step 2 and 3</li>' +
          '<li>Move all checks that require user input to the top</li>' +
          '<li>Disable verification if all checks are disabled</li>' +
          '<li>Disable verification export on verification failure</li>' +
          '</ul>'
      },
      {
        title: 'Quick Access to Drone Settings',
        description:
          'The drone settings can now be easily accessed from the drone card found ' +
          "in the Android app's home page. Pilots can quickly change drone settings " +
          'and get back to flying.'
      },
      {
        title: 'Increase Max. Zoom on All Maps',
        description:
          'For small sites and points of interest, it is now possible to zoom ' +
          'further into the map than before. This enhancement allows you to inspect ' +
          'boundaries and check map tiles with greater precision, ensuring better ' +
          'accuracy and detail in your mission planning.'
      }
    ],
    bugs: [
      {
        title: 'Incorrect Site Flying Time on Mission Update',
        description:
          'The site flying time shown in the site toolbar was not being updated ' +
          'when a single mission was drawn/updated/deleted resulting in the site ' +
          'not being marked as complete. This bug has been put to rest.'
      },
      {
        title: 'Incorrect Org. Filters on Site Overview Page',
        description:
          'The site filters found in the site overview page were not being restored ' +
          'correctly on a new session. This annoying papercut has been fixed to our ' +
          'satisfaction.'
      },
      {
        title: 'Distorted Waypoints on Pausing Mission',
        description:
          'Mission waypoints were distorted when the pilot pauses the mission, flies ' +
          'the drone manually back to the launch point and then lands the drone ' +
          'instead of using RTH. This resulted in DMO recording the new landing ' +
          'location as the resume point resulting in the distortion. This unique ' +
          'bug required us to revisit the mission progress compute logic and ' +
          'account for a couple of these strange scenarios.'
      },
      {
        title: 'Mission Upload Failed When Uploading 100 Waypoints',
        description:
          'Mission upload was found to fail in a rare scenario where the mission ' +
          'has exactly 100 waypoints. This bug only affected older drones such as ' +
          'the Phantom 4 series. DJI Enterprise drones were unaffected.'
      }
    ],
    faqs: []
  },
  {
    version: '2024.4',
    caption: 'Big Upgrades & Small Polishes',
    product: 'DMO Web Portal & Android App',
    date: '2024-04-02',
    features: [
      {
        title: 'Thermal Mapping',
        description:
          'Previous updates introduced thermal enhancements like temperature spot ' +
          "checks and side-by-side viewers. In this update, we've built upon these " +
          'features by adding support for thermal mapping. Pilots can now set ' +
          'custom drone heading and gimbal pitch values for precise thermal mapping.',
        image: {
          src: `${BASE_PATH}/thermal-mapping-sidebar.png`,
          height: 500,
          xsHeight: 300
        }
      },
      {
        title: 'Collection Feature Upgrade',
        description:
          'The collection view has been upgraded to show a map view of all site ' +
          'locations, showcasing their respective progress! This update offers users ' +
          "a bird's-eye view of the collection progress. Additionally, the aggregated " +
          'area, length and asset count are now displayed for better insights.',
        image: {
          src: `${BASE_PATH}/collection-details-page.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'Line Measure Tool',
        description:
          "We've introduced a line measure utility map tool on the site page for " +
          'rapid measurement of distances between GCPs, points of interest, and ' +
          'other elements. This feature will be further expanded to include area ' +
          'and point measure tools in upcoming updates.',
        image: {
          src: `${BASE_PATH}/line-measure-tool.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'Mavic 3T RGB Mapping Support',
        description:
          'The DJI Mavic 3T supports two different image sizes 12 MP and 48 MP. Until ' +
          'now, mission planning was only supported for the 48 MP wide camera. This ' +
          'has been extended to enable users to plan missions specifically for both ' +
          'the 12 MP and 48 MP cameras.'
      },
      {
        title: 'Tap To Focus Camera Support',
        description:
          'Pilots can now tap on the camera feed to focus the wide and zoom camera. ' +
          'With this, three widgets: auto-exposure lock, focus mode switch and focus ' +
          'exposure switches have been added to the camera feed screen.'
      }
    ],
    improvements: [
      {
        title: 'Site Toolbar Polish',
        description:
          'The site toolbar has been refined to show the flown progress along ' +
          'with a concise visualisation of site parameters including total ' +
          'flying time, photo count, GCP count, and other relevant metrics. ' +
          'Basically more useful information visible in the same space.',
        image: {
          src: `${BASE_PATH}/site-toolbar-polish.png`,
          height: 50,
          xsHeight: 50
        }
      },
      {
        title: 'Mission Sidebar Polish',
        description:
          'The mission sidebar has been polished to visually represent mission ' +
          'parameters such as course angle, drone heading and gimbal pitch. These ' +
          'improvements aim to facilitate better understanding, particularly for ' +
          'new users.'
      },
      {
        title: 'Switch to Satellite Layer By Default',
        description:
          'A subtle yet significant change is the switch to satellite map layer ' +
          'across all pages. This adjustment was prompted by valuable feedback ' +
          'from pilots and mission planners, who highlighted the importance of ' +
          'utilizing satellite imagery while planning missions and GCPs.'
      },
      {
        title: 'Export Failed Images as CSV',
        description:
          'Images that fail any data checks can now be exported as a CSV file for ' +
          'reporting purposes. This functionality allows users to filter out ' +
          'problematic images from the dataset before processing it further.'
      },
      {
        title: 'Show Pilot Transmitter/Phone Heading on Map',
        description:
          'The user location displayed on the map now also includes the directional ' +
          'orientation of the user device (phone/transmitter). This would be useful ' +
          'when drawing sites and missions, as well as to spot the drone easily.'
      }
    ],
    bugs: [
      {
        title: 'Mission Execution Allowed Even With Low GPS Strength',
        description:
          'The pre-flight check previously permitted pilots to commence flying ' +
          'even when the GPS signal strength was insufficient to accurately ' +
          'record the home location. To enhance safety measures, this loophole ' +
          'has been addressed to prevent the initiation of flights under such ' +
          'conditions.'
      },
      {
        title: 'Drone Randomly Pauses During Mission Execution',
        description:
          'The drone was observed to intermittently pause during mission execution, ' +
          'initially appearing random. However, upon closer examination, we ' +
          'discovered that this behavior is a built-in safety feature of DJI drones. ' +
          'If the drone loses GPS signal during mission execution, it automatically ' +
          'pauses to ensure its safety. The pilot will be kept informed about this ' +
          'safety feature to enhance their understanding and clarity during operation.'
      },
      {
        title: 'Mission Progress Lost Intermittently',
        description:
          'The mission progress sync feature had become flaky over the past few ' +
          'months, prompting us to conduct a thorough investigation. As a result, ' +
          'we have identified and addressed multiple bugs affecting mission ' +
          'The sync functionality has been significantly improved for enhanced ' +
          'reliability. We extend our gratitude to the DMO pilots who provided ' +
          'valuable feedback and reported these issues.'
      },
      {
        title: 'Incorrect Mavic 2 Pro Shutter Speed Detected in Data Check',
        description:
          'The shutter speed check was consistently failing on the Mavic 2 Pro due ' +
          'to incorrect reading of the information from the image metadata. This ' +
          'issue has also been put to rest.'
      },
      {
        title: 'Inaccurate Computation of Overlap Failure',
        description:
          'The Overlap Check accurately identified areas where the overlap was ' +
          'compromised, but failed to flag it as a failure. This issue has now ' +
          'been rectified.'
      },
      {
        title: 'Intermittent Data Check Timeout',
        description:
          'The Data Check feature was timing out when processing complex flight ' +
          'boundaries, due to server spikes. An investigation was conducted ' +
          'to resolve the issue promptly and ensure seamless functionality for you.'
      }
    ],
    faqs: []
  },
  {
    version: '2024.3',
    caption: 'Polish, Upgrade & Papercuts',
    product: 'DMO Web Portal & Android App',
    date: '2024-03-07',
    features: [
      {
        title: 'Automatic GCP Verification',
        description:
          'The Data Check feature is now upgraded with GCP verification, enhancing ' +
          'dataset accuracy. Automatically scan for GCPs in images, vital for ' +
          'absolute precision. Quickly spot missing GCPs, ensuring data integrity. ' +
          'Save time and effort with AI-powered detection, empowering seamless ' +
          'workflow for pilots.',
        image: {
          src: `${BASE_PATH}/gcp-verification-result.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'Android App Auto-Updater',
        description:
          'DMO Android running on DJI controllers like DJI RC Pro Enterprise utilized ' +
          'by Mavic 3E and other enterprise drones, now features automatic app update ' +
          'prompts. Pilots will always have the latest software for optimal user ' +
          'experience.'
      },
      {
        title: 'Monitor Stream Strength & Viewer Count on Android',
        description:
          'Live streaming pilots can now monitor the viewer count and signal ' +
          'strength in real-time, enhancing situational awareness of their live ' +
          'stream status.',
        image: {
          src: `${BASE_PATH}/live-stream-status-android.png`,
          height: 400,
          xsHeight: 200
        }
      }
    ],
    improvements: [
      {
        title: 'Compact & Faster Collection Card',
        description:
          'The collection cards have been redesigned for simplicity, compactness and ' +
          'lightning-fast loading times - now 90% faster! Experience zippy page ' +
          'loads and smoother navigation, demonstrating our dedication to ' +
          'improving user satisfaction!'
      },
      {
        title: 'Easier Identification of Sample, External & Owned Sites',
        description:
          'Distinguish external and sample sites from other DMO Account or ' +
          'Organisation owned sites effortlessly. This minor improvement alleviates ' +
          'user frustration and enhances usability.',
        image: {
          src: `${BASE_PATH}/distinct-sample-ext-sites.png`,
          height: 200,
          xsHeight: 200
        }
      },
      {
        title: 'Android App Optimized For Screen Efficiency',
        description:
          'The DMO Android app now optimizes screen space, offering a fullscreen ' +
          'experience and automatically hiding the bottom navigation bar when ' +
          'user scrolls. Paired with the new compact collection cards, this ensures ' +
          'easy accessibility to sites for pilots.',
        image: {
          src: `${BASE_PATH}/screen-efficient-android-app.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Refined GCP Plan Toolbar & Spacing Limits',
        description:
          'The GCP Plan toolbar has been polished to enhance user experience on ' +
          'smaller screens. This unlocked the request to increase the spacing limit ' +
          'to 1000 meters, aligning it with the capabilities of the latest ' +
          'generation drones equipped with RTK support.'
      },
      {
        title: 'Updated Airspace Advisory Styles',
        description:
          'The airspace advisory seen on the Android app has been given a facelift ' +
          'to avoid overcrowding in areas where multiple advisories are present. ' +
          'The advisory accuracy has also been improved along with more layers being ' +
          'added.',
        image: {
          src: `${BASE_PATH}/new-utm-android.png`,
          height: 400,
          xsHeight: 200
        }
      }
    ],
    bugs: [
      {
        title: 'Mavic 3E Mic Not Being Detected',
        description:
          'The mic of the DJI RC Pro Enterprise used by the Mavic 3 Enterprise was ' +
          'not being detected resulting in the pilot voice not heard during a live ' +
          'stream. This briefly broke the 2-way communication between the pilot and ' +
          'remote viewers. This bug has finally and satisfactorily been fixed!'
      }
    ],
    faqs: []
  },
  {
    version: '2024.2',
    caption: 'Thermal Goodies',
    product: 'DMO Web Portal & Android App',
    date: '2024-02-13',
    features: [
      {
        title: 'Thermal Side-by-Side Viewer',
        description:
          'This release introduces advanced support for thermal drones with the addition ' +
          'of the side-by-side viewer. Pilots can simultaneously view the visual ' +
          'and thermal imaging which would be handy during inspection, search ' +
          'and rescue operations as well as improving the situational awareness of ' +
          'the pilot during flight.',
        image: {
          src: `${BASE_PATH}/sbs-viewer.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Thermal Temperature Spot Check Tool',
        description:
          'Tap on an object on the screen to instantly view its real-time ' +
          'temperature. Another invaluable inspection tool to add to your arsenal.',
        image: {
          src: `${BASE_PATH}/temperature-spot-check.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Data Check Custom Elev. Support',
        description:
          'The newly introduced Data Check feature now supports custom elevation ' +
          'uploads, enabling pilots to verify mining data with precision. Have you ' +
          'had the chance to explore the Data Check feature yet? It is a game ' +
          'changer for ensuring accuracy in your drone operations.'
      },
      {
        title: 'GCP Planning on Android',
        description:
          'The complete suite of Ground Control Point (GCP) features, and more, ' +
          'is now accessible on DMO Android. This includes planning, editing, ' +
          'deleting, and auto-generating GCPs. Moreover, the DMO Android app ' +
          'intelligently displays GCPs during drone missions, allowing pilots to ' +
          'confirm their visibility in the camera feed.',
        image: {
          src: `${BASE_PATH}/android-gcp-plan-mode.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Quickly Share Live Stream URL',
        description:
          'Instantly share live stream URLs using QR codes. Generate a unique ' +
          'QR code for your live stream, and quickly share it with others.'
      }
    ],
    bugs: [
      {
        title: 'GCP Generation Fails For Complex Corridor Geometries',
        description:
          'In certain complex corridor geometries, the automatic GCP generation ' +
          'introduced in the previous release, would fail due to some transformation ' +
          'bug. This has been put to rest!'
      },
      {
        title: 'ESC Button Not Working During Draw Mode',
        description:
          'On entering the draw mode of sites/missions, the tooltip hinted at using ' +
          'the ESC key to exit the draw mode except that it did not work. This ' +
          'breakage has been fixed to make it intuitive and easier for new users.'
      },
      {
        title: 'Image Cut Off In Image Viewer Dialog',
        description:
          'Images from certain drone models like the DJI M3E would appear cut off ' +
          'in the Image Viewer Dialog. This inconvenience has been fixed to our ' +
          'satisfaction.'
      },
      {
        title: 'Entire Mission Not Visible in Execution Screen',
        description:
          'The entire mission boundary was not visible in the execution screen. It ' +
          'appeared cut off near the edges of the screens. This has been simply fixed ' +
          'by adding some paddings.'
      }
    ],
    improvements: [
      {
        title: 'Tons of Verification Workflow Polish',
        description:
          '<ul>' +
          '<li>Replaced gimbal pitch number input to slider to prevent bad input</li>' +
          '<li>Select a dataset by drag-n-drop</li>' +
          '<li>Display image name on hovering over an image marker on the map</li>' +
          '<li>Delete images based on their location using the map</li>' +
          '<li>Sort the check results based on their criticality</li>' +
          '<li>Support sharing of data verification results</li>' +
          '<li>View all failed images thumbnails in the results step</li>' +
          '<li>Show dataset count errors immediately in step 1 (Select Dataset)</li>' +
          '<li>Simplified and polished step 2 (Configuring Verification)</li>' +
          '<li>Center to images on the map on addition/deletion of an image</li>' +
          '<li>Remove confusing auto-opening of file selection dialog behavior</li>' +
          '</ul>'
      },
      {
        title: 'Stream Recording Thumbnails',
        description:
          'Cloud recordings of live streams now show a thumbnail for easier ' +
          'identification in the live stream page.'
      },
      {
        title: 'More Accurate Battery Estimates',
        description:
          'The battery estimates of enterprise drones are more accurate now thanks ' +
          'to using drone model specific attributes when computing the estimates. '
      },
      {
        title: 'Polished User Profile Menu',
        description:
          'The user profile menu seen on clicking the user avatar in the sidebar ' +
          'has been refined to be more useful by displaying the logged in ' +
          'user profile picture, name and email.'
      }
    ],
    faqs: []
  },
  {
    version: '2024.1',
    caption: 'New Year, New Goodies',
    product: 'DMO Web Portal & Android App',
    date: '2024-01-13',
    announcements: [
      {
        message:
          'Happy New Year 2024 from the DMO Team! Excited times ahead as we kick ' +
          'of the year with a bang! Explore our new homepage, drone data check, ' +
          'and enjoy M300 support in our latest release and much more!',
        icon: 'mdi-party-popper',
        color: 'green'
      }
    ],
    features: [
      {
        title: 'Homepage: Launchpad for Vital Info & Features',
        description:
          'Unveiling our new homepage, a dynamic hub tailored to provide a ' +
          'comprehensive overview of your sites, real-time updates on ongoing ' +
          'livestreams, and a seamless integration of other key features. Quickly ' +
          'access features such as airspace check, data check, and industry news. ' +
          'Unlock advanced DMO user status with our new "Tip of the Day" feature.',
        image: {
          src: `${BASE_PATH}/homepage.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'Drone Data Check',
        description:
          'Elevate your drone operations with the new on-site Drone Data Check feature. ' +
          'Instantly verify images, correct errors, and save time and money. With ' +
          '10+ checks ranging from ISO, Duplicate images to GSD and Overlap, ' +
          'enjoy peace of mind and maximise efficiency in the field. ' +
          'Pilot with confidence, powered by Drone Mission Ops.',
        image: {
          src: `${BASE_PATH}/data-check-mvp.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'DJI Matrice 300 Now Supported',
        description:
          'Introducing support for the DJI M300 drone with P1 sensor for flawless ' +
          'mapping missions. Upgrade your app to the latest version to unlock this ' +
          'feature.'
      },
      {
        title: 'DMO Now Speaks: Hindi',
        description:
          'Breaking barriers! We are thrilled to announce that Drone Mission Ops ' +
          'is now available in Hindi to meet the diverse needs of drone pilots. ' +
          'Experience the power of precision and simplicity your way, in Hindi! ' +
          'More languages on the way.',
        image: {
          src: `${BASE_PATH}/hindi-multilingual.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Geofence: Local No-Fly Zones',
        description:
          'Introducing Geofence: Your Guardian in the Skies! Draw no-fly zones ' +
          'effortlessly, ensuring safe and secure drone flights. Stay in control ' +
          'with automatic alerts and return to launch for worry-free flying. ' +
          'Elevate your safety with Drone Mission Ops!',
        image: {
          src: `${BASE_PATH}/geofence.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Autogenerate GCPs (Beta)',
        description:
          'Now, effortlessly plan Ground Control Points for large sites with just ' +
          'a click. Building on the success of the beloved mission generation ' +
          'feature, we have extended the ease and efficiency to GCPs. Experience ' +
          'enhanced productivity and streamlines operations as we continue to ' +
          'empower your tasks. Your success is our priority!',
        image: {
          src: `${BASE_PATH}/autogenerate-gcps.png`,
          height: 300,
          xsHeight: 150
        }
      }
    ],
    improvements: [],
    bugs: [],
    faqs: []
  },
  {
    version: '2023.11',
    caption: 'Enhanced Live Stream',
    product: 'DMO Web Portal & Android App',
    date: '2023-11-17',
    features: [
      {
        title: 'Real-Time AI Object Detection',
        description:
          "Building upon DMO's groundbreaking drone video call capability, we are " +
          'thrilled to introduce real-time object detection in the drone feed! You ' +
          'can now easily identify persons, cars, trucks, and more directly from ' +
          'your drone perspective. This opens up a range of security applications, ' +
          'including intruder detection and identifying unauthorized cars. With ' +
          'complete control in the hands of the remote viewer, you have the power ' +
          'to toggle this feature as needed and focus on specific objects of interest.',
        image: {
          src: `${BASE_PATH}/object-detection.png`,
          height: 600,
          xsHeight: 250
        }
      },
      {
        title: 'Live Stream Cloud Recording',
        description:
          'Explore a consolidated library of past drone video call recordings, ' +
          'conveniently located in one central place. Delve into the archives ' +
          'to gather detailed information about each call, including the list ' +
          'of attendees, capture location, and more. This feature also serves ' +
          'as a valuable resource for conducting security audits. <b>Ensure that ' +
          'pilots are on Android app v2023.11 or newer!</b>',
        image: {
          src: `${BASE_PATH}/cloud-recording-playback.png`,
          height: 600,
          xsHeight: 250
        }
      },
      {
        title: 'View GCPs on DMO Android',
        description:
          'GCPs planned for a site on the Web Portal are now visible on the Android ' +
          'app. This enhancement ensures that pilots and the DGPS team are ' +
          'synchronized, promoting smoother coordination. Keep an eye out for more ' +
          'improvements in the upcoming updates as we continue to expand this ' +
          'feature for an even better user experience!',
        image: {
          src: `${BASE_PATH}/android-gcps.png`,
          height: 400,
          xsHeight: 250
        }
      }
    ],
    improvements: [
      {
        title: '90% Faster Sites Loading Time',
        description:
          'Get ready for another speed boost! Your sites will now load a whopping ' +
          '90% faster than before. While it might not be faster than the speed of ' +
          'light, you will definitely notice the difference, especially when dealing ' +
          'with a large number of sites. This improvement is particularly impactful ' +
          'as it is the first thing users see upon opening DMO Web/Android. Enjoy ' +
          'the smoother experience - our commitment to enhancing user satisfaction!'
      }
    ],
    bugs: [
      {
        title: 'Asset Site Waypoint Mission Broken',
        description:
          'Waypoint mission type execution for asset sites was broken in our previous ' +
          'update due to a failed elevation check. The good news is that this issue ' +
          'exclusively affected asset sites, and other sites were unaffected by this ' +
          'bug. Rest assured, we have swiftly addressed this issue.'
      },
      {
        title: 'Mission Upload Failure Due To Exceeding Max. Waypoint Count',
        description:
          'Safety waypoints added in a previous mission were not being cleared ' +
          "resulting in the next mission's waypoint count exceeding the max. " +
          'count set by DJI. This would result in the mission upload failing. ' +
          'This bug was challenging to pinpoint and investigate due to specific ' +
          'conditions required for triggering, but our team has successfully ' +
          'addressed it. We are delighted to see this bug squashed.'
      },
      {
        title: 'App Crash On Removing Earphone After Stopping Live Stream',
        description:
          'There were some clean ups done after a live stream is stopped which in ' +
          'combination with an earphone removal event triggered an app crash. This ' +
          'bug faced by a few users has been put to rest.'
      },
      {
        title: 'Camera Feed Cut-Off In Preflights Screen',
        description:
          'The camera feed in the preflights screen would appear cut off momentarily ' +
          'on taking off. This bug has been fixed.'
      },
      {
        title: 'App Crash While Editing Area Mapping Mission',
        description:
          'In certain scenarios, the app crashed while editing an area mapping ' +
          'mission due to invalid values being passed to the slider inputs. They ' +
          'clearly do not like that and crashed the app. They have been taken ' +
          'care of.'
      },
      {
        title: 'Rare App Crash On Completing Waypoint',
        description:
          'There was a rare app crash on completion of a waypoint due to multiple ' +
          'threads trying to execute the same function at the same time. Fear not, ' +
          'our debugging superheroes swooped in and squashed this bug.'
      }
    ],
    faqs: []
  },
  {
    version: '2023.10',
    caption: 'Faster, Modern Architecture',
    product: 'DMO Web Portal & Android App',
    date: '2023-10-16',
    features: [
      {
        title: 'DJI Mavic 3E Official Support',
        description:
          'We are thrilled to bring you some exciting news: Drone Mission Ops has ' +
          'expanded its support to include the DJI Mavic 3 Enterprise series drones! ' +
          'Elevate your missions with enhanced capabilities and seamless integration. ' +
          'Your missions just got a whole lot more exciting and productive.'
      }
    ],
    improvements: [
      {
        title: 'Cleaner & Simplified Airspace Advisories',
        description:
          'The airspace advisories on the map have been tidied up to prevent ' +
          'clutter by merging shapes into an unified display. Additionally, the ' +
          'Indian Digital Sky advisories (Red, Yellow & Green) are now more prominent ' +
          'and updated in real-time.',
        image: {
          src: `${BASE_PATH}/new-utm-layers.png`,
          height: 400,
          xsHeight: 250
        }
      },
      {
        title: 'Quickly Access Recent Cameras',
        description:
          'Recently used cameras for mission planning are now conveniently placed ' +
          'at the top for quick access, ensuring a smoother experience for ' +
          'mission planners. It is the little things that make your daily experience ' +
          'even better. We hope this brings a smile to your face.',
        image: {
          src: `${BASE_PATH}/recent-cameras.png`,
          height: 250,
          xsHeight: 250
        }
      },
      {
        title: 'Much Faaster Bulk Mission Delete',
        description:
          'We have turbocharged mission deletion. It is now lightning fast, nearly ' +
          '700% faster when removing 20+ missions. It is as quick as a blink. Just ' +
          'the way it should be!'
      },
      {
        title: 'Improved Site Boundary Contrast',
        description:
          'The site boundary color contrast against the satellite layer has been ' +
          'enhanced to make it even more visible. Another little refinement to ' +
          'improve your experience.'
      }
    ],
    bugs: [
      {
        title: 'Rapid Input Crashes The Android App',
        description:
          'In certain rare scenarios, drawing or undoing site vertices rapidly would ' +
          'result in the app crashing due to an index issue. It is like the app is ' +
          'human and lost count in the middle of the draw workflow. Its processing ' +
          'speed has  been upgraded to fix this bug.'
      }
    ],
    faqs: []
  },
  {
    version: '2023.9',
    caption: 'Balanced Bliss of Features & Polish',
    product: 'DMO Web Portal & Android App',
    date: '2023-09-02',
    features: [
      {
        title: 'Android 13 Support',
        description:
          'The DMO Android app is now fully compatible with Android 13! This ' +
          'brings themed app icons which tint to match your phone wallpaper color, ' +
          "among other improvements. Google's Material You for the win.",
        image: {
          src: `${BASE_PATH}/themed-icons-cropped.jpg`,
          height: 300,
          xsHeight: 300
        }
      },
      {
        title: 'More Mission Editing Controls on Android App',
        description:
          'Pilots now have the ability to fine-tune mission parameters, including ' +
          'GSD, front & side overlap, drone speed and camera model directly in the ' +
          'Android app. This empowers pilots with more control and flexibility.',
        image: {
          src: `${BASE_PATH}/android-mission-edit-sidebar.png`,
          height: 400,
          xsHeight: 250
        }
      },
      {
        title: 'Export Missions to Mission Planner & Litchi',
        description:
          "Effortlessly create missions using DMO's streamlined planning workflow, " +
          'enabling quick mission creation for large areas. You can now export these ' +
          'missions to Mission Planner for drones using Pixhawk autopilot and to ' +
          'Litchi for other popular consumer drones like Air 2S, Mavic Mini etc.',
        image: {
          src: `${BASE_PATH}/mission-export.png`,
          height: 250,
          xsHeight: 250
        }
      }
    ],
    improvements: [
      {
        title: 'User Preferences Stored On Organization Level',
        description:
          'User preferences such as site filters, grouping and view mode are now ' +
          'stored at the organization level tailoring your personal and ' +
          'organization experience.'
      },
      {
        title: 'Quick Site Actions',
        description:
          'Need to quickly share or export a site? Now, it is a breeze to do ' +
          'it directly from the site card, no need to open the site first.',
        image: {
          src: `${BASE_PATH}/quick-site-actions.png`,
          height: 300,
          xsHeight: 300
        }
      },
      {
        title: 'Create Site Using KMZ File on Android App',
        description:
          'Pilots now have the option to upload both KMZ and KML files to create ' +
          'sites on the Android app.'
      }
    ],
    bugs: [
      {
        title: 'Android App Crash During Sign Up',
        description:
          'Fixed a crash that was triggered when the user quickly pressed the ' +
          'home button after starting the sign up flow. The Android app was ' +
          'overwhelmed by the rapid user input resulting in a crash.'
      },
      {
        title: 'Android App Crash When Editing User Profile',
        description:
          'Attempting to edit the user profile crashed the Android app. This ' +
          'bug has been squashed. You may now go ahead and update your identity, ' +
          'John Doe.'
      },
      {
        title: 'Duplicate Site Invites Allowed',
        description:
          'The Web Portal allowed sending sites to the same user multiple times ' +
          'resulting in duplicate site invites. This was a harmless bug that has ' +
          'been fixed regardless.'
      },
      {
        title: 'Site Invite Not Closed on Site Deletion',
        description:
          'Invites of a deleted site were not expired automatically resulting ' +
          'in them being left behind. Another harmless bug that has been put to ' +
          'rest.'
      }
    ],
    faqs: []
  },
  {
    version: '2023.8',
    caption: 'Polish & Papercuts',
    product: 'DMO Web Portal & Android App',
    date: '2023-08-04',
    features: [],
    improvements: [
      {
        title: 'Usability Improvements to GCP Planning Workflow',
        description:
          '<ul>' +
          '<li>' +
          'You now have the flexibility to change the color of the GCP spacing ' +
          'circle, ensuring better visibility on the satellite layer. We have also ' +
          'fine-tuned its opacity, making it even more user-friendly for evenly ' +
          'spacing GCPs across your site.' +
          '</li>' +
          '<li>' +
          'Say goodbye to the inconvenience of toggling the elevation heat map off ' +
          'to view the satellite layer. You now have the option to adjust the heat ' +
          'map opacity, allowing you to plan GCPs effortlessly.' +
          '</li>' +
          '</ul>',
        image: {
          src: `${BASE_PATH}/gcp-spacing-colors.png`,
          height: 50
        }
      },
      {
        title: 'Site Share Workflow Polish',
        description:
          '<ul>' +
          '<li>' +
          "The logged-in user and the site author's access level is always shown " +
          'for quick reference' +
          '</li>' +
          '<li>' +
          'Streamlined sharing of a personal space site with a guest' +
          '</li>' +
          '<li>' +
          'More intuitive listing of different site access levels with helpful hints' +
          '</li>' +
          '</ul>',
        image: {
          src: `${BASE_PATH}/site-access-hints.png`,
          height: 400
        }
      },
      {
        title: 'Org. Members Management Polish',
        description:
          '<ul>' +
          '<li>' +
          'Show a "New" badge next to recently added members to easily identify ' +
          'new team members who joined within the past week.' +
          '</li>' +
          '<li>' +
          'Prevent users from changing their own roles to avoid potential situations ' +
          'where they lose management permissions.' +
          '</li>' +
          '</ul>'
      }
    ],
    bugs: [],
    faqs: []
  },
  {
    version: '2023.7',
    caption: 'Core Experience Upgrade',
    product: 'DMO Web Portal & Android App',
    date: '2023-07-23',
    announcements: [
      {
        message:
          'In this update, we have completely revamped essential elements of DMO, ' +
          'focusing on your DMO Account, Organization, and Site Collaboration. ' +
          "We can't wait for you to experience all the exciting enhancements " +
          "we've made!",
        icon: 'mdi-rocket-launch',
        color: 'green'
      }
    ],
    features: [
      {
        title: 'One DMO Account To Rule Them All',
        description:
          'Experience the new DMO Account which is a lot more flexible and powerful ' +
          'with the user in complete control of his account. ' +
          "Let's break it down." +
          '<ul class="mt-1">' +
          '<li>' +
          'A DMO Account provides a personal space for your very own sites and ' +
          'collections. Enjoy the privacy of your content, as it remains complete ' +
          'private and viewable only by you. Happy freelancing!' +
          '</li>' +
          '<li class="mt-1">' +
          'Join a DMO Organization and fly for them separately. Think of this as ' +
          '<i>Personal</i> and <i>Work</i> profiles. They are separate and ' +
          'empowering. You can join a DMO Organization by receiving an invitation, ' +
          'and only upon accepting it, will you become a part of the organization. ' +
          'You can leave the organization at any time and join another.' +
          '</li>' +
          '<li class="mt-1">' +
          'Consolidate all your flying experiences across DMO Organizations ' +
          'in one secure place - your DMO Account. Keep your precious flying safe ' +
          'and accessible.' +
          '</li>' +
          '<li class="mt-1">' +
          'Effortlessly switch between your private and organization views with ' +
          'just a simple click.' +
          '</li>',
        image: {
          src: `${BASE_PATH}/org-switcher-view-zoomed.png`,
          height: 400,
          xsHeight: 300
        }
      },
      {
        title: 'Set up your business on DMO',
        description:
          'Are you a drone service provider? Struggling to effectively manage ' +
          'your fleet and drone operations? Look no further than DMO Organization - ' +
          'the ultimate solution to your challenges! Create a DMO Organization ' +
          'today and experience streamlined drone operations like never before. ' +
          'Establish your brand on DMO!',
        image: {
          src: `${BASE_PATH}/create-org-workflow.png`,
          height: 500,
          xsHeight: 400
        }
      },
      {
        title: 'Intuitive DMO Organization Member Management',
        description:
          'The new revamped organization member management is transparent and ' +
          'easy to use.' +
          '<ul class="mt-1">' +
          '<li>' +
          '<b>Invite users</b> to your DMO Organization. Keep track of pending invites ' +
          'and once accepted, they will seamlessly join your organization.' +
          '</li>' +
          '<li class="mt-1">' +
          '<b>Assign different access levels</b> to users, such as member, admin, or ' +
          'co-owner, effectively managing their privileges within your organization.' +
          '</li>',
        image: {
          src: `${BASE_PATH}/org-members.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'Powerful Site Collaboration',
        description:
          'The new site collaboration is powerful in 2 ways,' +
          '<ul class="mt-1">' +
          '<li>' +
          '<b>Unlimited Collaborators</b>: Now, you can add as many collaborators ' +
          'as you want, no longer restricted to just one. By default, your ' +
          'organization members will have view access, and you have the flexibility ' +
          'to set their access level for each site.' +
          '</li>' +
          '<li class="mt-1">' +
          '<b>Fine-Grained Access Control</b>: Take charge of your shared sites! With ' +
          "DMO's fine-grained access levels, you decide whether collaborators have " +
          'view-only, edit, or full access rights when collaborating on a site. ' +
          'Your collaboration, your control.' +
          '</li>',
        image: {
          src: `${BASE_PATH}/site-share-v2-dialog.png`,
          height: 500,
          xsHeight: 400
        }
      },
      {
        title: 'Manage Organization Guests In One Place',
        description:
          'Introducing a centralized hub for monitoring all external ' +
          'collaborations. Ensure the security of your drone operations ' +
          'by periodically reviewing and managing permissions. ' +
          "With just one click, you can easily revoke a guest's access to a " +
          'site or remove all their permissions at once.',
        image: {
          src: `${BASE_PATH}/org-guests.png`,
          height: 600,
          xsHeight: 300
        }
      }
    ],
    improvements: [
      {
        title: 'Usability Improvements to Waypoint Missions',
        description:
          'A bunch of usability improvements for waypoint missions have landed to ' +
          'make mission planning more streamlined. Some of these are listed below, ' +
          '<ul class="mt-1">' +
          '<li>' +
          'Waypoint heading mode is set to AUTO to ensure that the drone is ' +
          'facing the flying path instead of defaulting to North.' +
          '</li>' +
          '<li>' +
          'Easily identify which waypoint is invalid when switching flight path ' +
          'mode or saving the mission.' +
          '</li>'
      }
    ],
    bugs: [
      {
        title: 'Invalid Waypoint Count Due to Limit Breach',
        description:
          'On older DJI drones, mission uploaded to the drone should have a ' +
          'maximum of 99 waypoints. This max. count was being breached in ' +
          'some rare situations. This bug has been fixed for good.'
      },
      {
        title: 'Mission Characteristics Outdated Post Edit',
        description:
          'The mission characteristics such as flying time, battery count etc. ' +
          'were not updated after editing the mission parameters on the Android ' +
          'app. This bug has been squashed to our satisfaction.'
      },
      {
        title: 'Android App Crash in Mission Planning Workflow Due to Locale',
        description:
          'Identified a crash in the mission planning workflow due to locale. ' +
          'Apparently, in some locales, decimals are represented as ",". Good ' +
          'to hit these kind of issues.'
      },
      {
        title: 'Android App Crash in Drone Details Page',
        description:
          'There was a crash when opening the drone details page due to a ' +
          'missing image. Providing the correct path resolved the issue. Perhaps, ' +
          'Generative AI will help prevent silly issues like these in the future.'
      }
    ],
    faqs: []
  },
  {
    version: '2023.6',
    caption: 'Fly Complex 3D Drone Path',
    product: 'DMO Web Portal & Android App',
    date: '2023-06-06',
    features: [
      {
        title: 'New Mission Type: Waypoint Mission',
        description:
          '<ul>' +
          '<li>' +
          'Fly complex drone paths using a Waypoint Mission. Think of it as a middle ' +
          'ground between a manual flight (full freedom) and a grid mission ' +
          '(limited flexibility) where the drone flies a pre-planned grid.' +
          '</li>' +
          '<li class="mt-1">' +
          'What makes this mission type even more powerful is Waypoint Actions. ' +
          'An action could be to capture a photo, or start/stop video recording, ' +
          'rotate drone or gimbal etc. Each waypoint can have up to 15 actions. You ' +
          'can fly a 360 around a POI, or capture exciting movie like shots using ' +
          'your drone! ' +
          '</li>' +
          '<li class="mt-1">' +
          'Share your feedback on Waypoint Missions with us at ' +
          '<b>dmo.support@skylarkdrones.com</b>. We cannot wait to hear how you ' +
          'are using this feature.' +
          '</li>',
        image: {
          src: `${BASE_PATH}/waypoint-mission.png`,
          height: 500,
          xsHeight: 200
        }
      },
      {
        title: 'Multi Mission Type Support Within a Site',
        description:
          'With this update, different types of missions e.g area mapping mission and ' +
          'waypoint mission can be drawn in the same site! This comes handy when you ' +
          'want to capture multiple data types (images, videos) or fly different ' +
          'paths (grid mission, custom asset mission).',
        image: {
          src: `${BASE_PATH}/multi-mission-type-support.png`,
          height: 500,
          xsHeight: 200
        }
      },
      {
        title: 'Mission Plan for Ideaforge & Asteria Drones',
        description:
          "Mission planning is now supported for Ideaforge's Q6 and Ryno drone models " +
          "in addition to Asteria's A200! It is our pleasure to extend DMO support to " +
          'these players in the Indian ecosystem. In the next release, you will be ' +
          'able to export these missions to Mission Planner and other compatible GCS.'
      },
      {
        title: 'Modify Mission Parameters on the Android App',
        description:
          'Pilots can now modify mission parameters such as course angle, flying ' +
          'height, end mission action and gimbal pitch in the Android app. More ' +
          'parameters will be added in the near future.'
      }
    ],
    improvements: [],
    bugs: [
      {
        title: 'Mission Update Fails After Editing Critical Parameters',
        description:
          'In some rare cases, updating a mission on the Web Portal would throw a ' +
          'fit, complaining about missing mission parameters. This was due to the ' +
          'mission state being invalid. This bug has been squashed.'
      },
      {
        title: 'Incorrect User Assigned on Resetting Mission on Web Portal',
        description:
          'The pilot of an in-progress mission was incorrectly overwritten to the ' +
          'user who modifies a critical mission parameter such as overlap, drone ' +
          'speed etc. on the Web Portal. This error has been corrected.'
      },
      {
        title: 'Collections Search Broken in Overview Page',
        description:
          'The collections search on the Web Portal was always returning empty ' +
          'in the collections  overview page due to incorrect pagination (computer ' +
          'speak for efficient fetching of data). The incorrect translation has ' +
          'been fixed.'
      },
      {
        title: 'Site Export Fails For Site Names with Comma',
        description:
          'Exporting site elevations, gcps and missions failed due to a filename ' +
          'formatting error on Google Chrome, but not on Firefox. Welcome to the ' +
          'bizaaarre world of browsers. This bug has been squashed with rigour.'
      },
      {
        title: 'Flown Progress Not Plotted on Map in Offline Conditions',
        description:
          'The mission flown progress was not being plotted on the map in offline ' +
          'conditions. However, this bug did not affect the mission resume point. ' +
          'This source of confusion about the mission progress has been put to rest.'
      },
      {
        title: 'In-Progress Mission Boundary Not Hidden on Toggling Layer',
        description:
          'On the Web Portal, in-progress missions would leave behind their boundary ' +
          'on the map when toggling off the mission layer. This ghosting bug despite ' +
          'being a silly one has been fixed.'
      },
      {
        title: 'Empty User Name Allowed During Sign up',
        description:
          'The DMO Android app allowed users to sign up with an empty name. This may ' +
          'have allowed users to unintentionally mask their identity causing ' +
          'confusion to their peers. This occurred due to their name also missing ' +
          'in their Google Account.'
      },
      {
        title: 'App Crash in Preflight Screen When Using DJI Air 2S',
        description:
          'An unsupported video resolution of a planned mission caused the Android ' +
          'app to crash in the preflight screen when trying to execute the mission ' +
          'on a DJI Air 2S drone. Alternate resolutions are now shown to the pilot ' +
          'to pick from.'
      }
    ],
    faqs: []
  },
  {
    version: '2023.5',
    caption: 'Hone in your site & collection',
    product: 'DMO Web Portal',
    date: '2023-05-03',
    features: [
      {
        title: 'Powerful Site & Collection Filters',
        description:
          'Find the site and collection you are looking for, faster by filtering sites ' +
          'by name, date, author, and more on the DMO Web Portal! These filters are ' +
          'persistent across devices and sessions. Focus on a smaller set of sites ' +
          'to improve your productivity.',
        image: {
          src: `${BASE_PATH}/sites-filter-and-group.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'Site Grouping & Grid View',
        description:
          'Unlock a new perspective on your drone operations by grouping sites by ' +
          'status and date. Manage operations more efficiently by viewing them as ' +
          'a pipeline using the new column view.',
        image: {
          src: `${BASE_PATH}/sites-group-and-column-view.png`,
          height: 400,
          xsHeight: 200
        }
      },
      {
        title: 'Mission Plan for all DJI Consumer & Enterprise Drones',
        description:
          'All DJI consumer and enterprise drone and camera models have been added ' +
          'to DMO. This unlocks mission planning support for these drone models. ' +
          'Go ahead and generate missions for the new DJI Mavic 3E!'
      }
    ],
    improvements: [],
    bugs: [],
    faqs: []
  },
  {
    version: '2023.4',
    caption: 'Refined Drone Live Stream & Execution',
    product: 'DMO Web Portal & Android App',
    date: '2023-03-29',
    features: [
      {
        title: 'Live Stream Drone Feed (With Audio)',
        description:
          'The drone live stream feature was introduced in the previous release ' +
          '2023.3 to help conduct remote inspection of cell towers and other ' +
          'assets with the off-site engineer viewing the drone feed live. ' +
          'This feature has been enhanced in this release with the addition ' +
          'of audio integration that allows the off-site engineer and the drone ' +
          'pilot to talk to each other. This drastically improves the real-time ' +
          'collaboration experience! A few other polishes include, ' +
          '<ul class="mt-1">' +
          '<li>' +
          'Based on user feedback, the pilot network strength is shown next ' +
          'to the live counter for better awareness of the pilot condition on site.' +
          '</li>' +
          '<li>' +
          'The public stream viewing experience has been streamlined to reduce ' +
          'friction and to get to the drone feed with minimal steps.' +
          '</li>' +
          '<li>' +
          'Share your live streaming experience with us at ' +
          '<b>dmo.support@skylarkdrones.com</b>. We cannot wait to hear how you ' +
          'are using the live stream feature.' +
          '</li>',
        image: {
          src: `${BASE_PATH}/live-stream-audio-integration.png`,
          height: 600,
          xsHeight: 300
        }
      },
      {
        title: 'Offline Mission Execution',
        description:
          'Pilots can now execute missions completely offline! This will be handy ' +
          'while flying missions in remote areas where network connectivity ' +
          'might be poor. The site details are cached on opening the first time, ' +
          'subsequently allowing for offline access.'
      },
      {
        title: 'Granular Mission Management',
        description:
          'Mission planners have been benefited by the highlight automatic mission ' +
          'planning feature that covers most use cases. However, some situations ' +
          'call for the need to create, modify and delete individual missions ' +
          'which is now fully supported in this release.'
      }
    ],
    improvements: [],
    bugs: [
      {
        title: '3 Android App Crashes',
        description:
          '3 crashes were detected and have been fixed with this release. None of ' +
          'them occurred during mission execution. We are continuously monitoring the ' +
          'situation and improving the app stability. We highly recommended pilots to ' +
          'be on the latest version for a smoother flying experience.'
      }
    ],
    faqs: []
  },
  {
    version: '2023.3',
    caption: 'Drone Live Stream Debut',
    product: 'DMO Web Portal & Android App',
    date: '2023-03-04',
    features: [
      {
        title: 'Live Stream Drone Feed',
        description:
          'Pilots can now live stream their drone feed using the DMO Android App! ' +
          'This makes it really easy to conduct remote inspection of cell towers ' +
          'and other assets with the engineer viewing the drone feed live. ' +
          'Pilots can also broadcast public events to their viewers seamlessly by ' +
          'just sharing a URL.' +
          '<ul class="mt-1">' +
          '<li>' +
          'The live stream can be private or public. If it is made public, anyone ' +
          'with the URL can view it. No DMO account required!' +
          '</li>' +
          '<li>' +
          'Starting a live stream is simple. All the pilot has to do is click on ' +
          'the start live stream button. Period. All the technical complexities ' +
          'are handled by DMO. No need to create a RTMP link or require any special ' +
          'application to view the stream.' +
          '</li>' +
          '<li>' +
          'Share your live streaming experience with us at ' +
          '<b>dmo.support@skylarkdrones.com</b>. We cannot wait to hear how you ' +
          'are using the live stream feature.' +
          '</li>',
        image: {
          src: `${BASE_PATH}/web-public-live-stream.png`,
          height: 600,
          xsHeight: 300
        }
      }
    ],
    improvements: [],
    bugs: [
      {
        title: 'In-progress Missions Fail to Plot',
        description:
          'In certain scenarios, missions which were partially flown by the pilot ' +
          'failed to plot on the Android app. This critical bug was sneaky as it ' +
          'raised no flags or crashes thereby going unnoticed. Nonetheless, this ' +
          'bug has been put to rest.'
      },
      {
        title: 'Searching Sites With Special Characters Fail',
        description:
          'Sites with special characters in their names would not appear when ' +
          'searching for them. This inconvenience has been fixed. Feel free to ' +
          'use any amount of special characters (!#-/+())'
      }
    ],
    faqs: []
  },
  {
    version: '2023.2',
    caption: 'Sites & Collections Polish',
    product: 'DMO Web Portal & Android App',
    date: '2023-01-26',
    features: [],
    improvements: [
      {
        title: 'Performance Boost',
        description:
          '<ul>' +
          '<li>Sped up mapping mission generation by 40%</li>' +
          '<li>Sped up video mission generation by 50%</li>' +
          '<li>Sped up mission deletion by 60%</li>' +
          '</ul>'
      },
      {
        title: 'Modify Corridor Mapping and Video Missions',
        description:
          'Starting with this update, all mission types can be individually modified ' +
          'to change their camera, geometry or any other mission parameter on the ' +
          'DMO Web Portal. Nothing holding you back anymore!'
      },
      {
        title: 'Site Characteristics Added Back',
        description:
          'Site characteristics such as the total flying time, battery and images ' +
          'count was dropped in the new Sites & Collections December update. We heard ' +
          'how much you missed it. It has been added back to the site details page ' +
          'toolbar in this release.'
      },
      {
        title: 'Persistently Stored GCP Influence Radius',
        description:
          'The GCP influence radius set for a site is now persistently saved unlike ' +
          'before where it would default to 175 meters on page refresh.'
      },
      {
        title: 'Catch Self-Intersecting Polygons During Upload',
        description:
          'Self-intersecting site geometry is invalid and is now identified ' +
          'correctly by the Android app and stopped from being uploaded.'
      }
    ],
    bugs: [
      {
        title: 'Mission Size and Progress Rounded Off to 0',
        description:
          'Small missions of less than 0.5 acres were incorrectly rounded off to 0 ' +
          'when computing their flown progress. This minor snafu has been fixed.'
      },
      {
        title: 'Sites Not Loading In Rare Case',
        description:
          'There was one rare scenario where sites would not load due to memory limit ' +
          'being hit. This critical bug has been squashed for good. We apologize for ' +
          'the inconvenience caused!'
      },
      {
        title: '7 Android App Crashes',
        description:
          '7 crashes were detected and have been fixed with this release. None of ' +
          'them occurred during mission execution. We are continuously monitoring the ' +
          'situation and improving the app stability. We highly recommended pilots to ' +
          'be on the latest version for a smoother flying experience.'
      },
      {
        title: 'All Missions Have the Same Course Angle',
        description:
          'Missions that were generated had the same course angle as the first ' +
          'mission instead of the optimal course angle resulting in the flying time ' +
          'being inefficient. This is what happens when computers copy code by ' +
          'reference. They perform the same operation in all the copied locations. '
      },
      {
        title: 'Home Elevation Fetch Failed Sometimes',
        description:
          'The Android app fetches the home point elevation from the server if it ' +
          'is not present locally. This operation failed due to the server API route ' +
          'being unavailable, resulting in the pilot being asked to enter the ' +
          'elevation manually. It is like making a phone call to a non-existent ' +
          'number. This bug has been resolved on the server side.'
      },
      {
        title: 'Site Geometry Not Simplified Before Save',
        description:
          'Redundant and duplicate points in the site geometry uploaded by the pilot ' +
          'could affect mission execution due to duplicate mission waypoints being added ' +
          'thereby breaching the minimum distance constraint imposed by DJI drones. ' +
          'This has been accounted for by simplifying the site geometry and removing ' +
          'these redundant points without any site topological change.'
      }
    ],
    faqs: []
  },
  {
    version: '2022.12',
    caption: 'Sites & Collections',
    product: 'DMO Web Portal & Android App',
    date: '2022-12-11',
    announcements: [
      {
        message:
          'The DMO Team is excited to announce the December 2022 release. An early ' +
          "Christmas gift. This release brings the <b>biggest change since DMO's " +
          'inception</b>! Read on to find out what has changed and how it would impact ' +
          'your drone operations.',
        icon: 'mdi-party-popper',
        color: 'green'
      }
    ],
    features: [
      {
        title: 'Open Workflow: Sites & Collections',
        description:
          'The project concept has been replaced with a more flexible and powerful ' +
          "workflow a.k.a Sites & Collections. Let's briefly go over the concept " +
          'to get a better understanding.' +
          '<div class="mt-2 mb-1"><b>Sites</b></div>' +
          '<ul>' +
          '<li>' +
          'A site is an area/corridor/asset that needs to be surveyed. For ' +
          'example, a mine (area) is a site. A cell tower (asset) is a site.' +
          '</li>' +
          '<li>' +
          'Draw or upload (kml/kmz) sites and plan your missions as usual.' +
          '</li>' +
          '<li>' +
          'Execute missions to capture drone data. Job done.' +
          '</li>' +
          '</ul>' +
          '<div class="mt-2 mb-1"><b>Collections</b></div>' +
          '<ul>' +
          '<li>' +
          'A collection is a group of sites. Think of it as a playlist of ' +
          'songs or an album of photos. They become useful when executing large ' +
          'drone operations.' +
          '</li>' +
          '<li>' +
          'You are free to create a collection of any sites. E.g collection of ' +
          'sites belonging to a client, work order or based on location.' +
          '</li>' +
          '<li>' +
          'Collection replaces the project concept which forced a workflow that ' +
          'may or may not work with your team. Collections is a more open workflow ' +
          'that adapts to your style.' +
          '</li>' +
          '</ul>',
        image: {
          src: `${BASE_PATH}/sites-n-collections.png`,
          height: 500,
          xsHeight: 300
        }
      },
      {
        title: 'More Powerful Android App',
        description:
          'A long standing request for a more powerful Android app has been finally ' +
          'addressed. Manage your drone operations on the move using your mobile or ' +
          'tablet! The Android app supports uploading sites, planning missions and ' +
          'monitoring execution! Pilots can now draw a site and execute missions on ' +
          'the field. There is no need to bring your laptop.'
      },
      {
        title: 'Simplified Mission Execution',
        description:
          'The old project and teams workflow was too rigid and bureaucratic. ' +
          'Seriously. A pilot had to create a project, upload an aoi, plan missions, ' +
          'create a team, assign missions and switch over to the Android app to ' +
          'execute a mission. That is a lot of steps. The new workflow is upload a ' +
          'site, plan missions and go fly. Period. This is not an exaggeration.'
      },
      {
        title: 'Revamped GCP Planning Workflow',
        description:
          'DMO is the one and only app providing an integrated GCP planning workflow. ' +
          'In this release, based on the feedback received, the GCP workflow has been ' +
          'revamped addressing some long standing issues while retaining the parts ' +
          'that customers loved. A new GCP Plan Mode makes it easier for planners to ' +
          'add/move/delete gcps while viewing supporting information such as the ' +
          'elevation heat map or the satellite map.',
        image: {
          src: `${BASE_PATH}/gcp-plan-mode.png`,
          height: 500,
          xsHeight: 200
        }
      }
    ],
    improvements: [],
    bugs: [
      {
        title: 'Stop Centering to the Drone Continuously',
        description:
          'In the free-flight screen, the map was centering to the drone continuously. ' +
          'This can get in the way of the pilot when he pans the map to check the ' +
          'surrounding area. This was an annoying bug that we had to fix right away.'
      },
      {
        title: 'Drone Connection Not Detected Sometimes',
        description:
          'The drone connection was not detected by the Android app if the drone was ' +
          'connected before opening the app. Sorry about that. This bug has been ' +
          'squashed to the delight of pilots everywhere.'
      },
      {
        title: 'Crash When Fetching City Name',
        description:
          'Fixed a crash when the app could not find the city name in the weather ' +
          'details page. Clearly, the app was frustrated about not finding the city ' +
          'name.'
      },
      {
        title: 'Crash in Preflight Checklist',
        description:
          'Fixed a crash in the preflights screen due to information dialogs appearing ' +
          'after the preflight screen was closed.'
      },
      {
        title: 'App State Not Preserved',
        description:
          'How would you feel if you had to keep introducing yourself to someone ' +
          "everytime you met them? Well, that's what would happen anytime you switch " +
          'to the Android home screen and return to the app. The app state would not ' +
          'be restored. This bug has been fixed to our satisfaction.'
      }
    ],
    faqs: [
      {
        title: 'How do I assign missions to my pilot?',
        description:
          "The short answer is you don't. All you need to do in the current workflow " +
          'is to create a site and plans missions. Your pilot can automatically see ' +
          'the site on his Android device. He just needs to open it and select a ' +
          'mission to fly.'
      },
      {
        title:
          'I am unable to find the DMO Verification App. Where can I find it?',
        description:
          'The DMO Verification App has been discontinued in its current form. ' +
          'Existing users who have it installed on their system can continue to use ' +
          'it. The data verification workflow will return in a different form in the ' +
          'future.'
      },
      {
        title:
          'Who do I contact for help or provide feedback about the new workflow?',
        description:
          'The DMO Team can be reached at <b>dmo.support@skylarkdrones.com</b>. We ' +
          'will be more than happy to provide any assistance in helping you get up ' +
          'to speed with the new workflow and listen to your feedback.'
      }
    ]
  },
  {
    version: '2022.9',
    caption: 'Free Verification App',
    product: 'DMO Android & Verification App',
    date: '2022-09-06',
    features: [
      {
        title: 'No DMO Account Required for Verification App',
        description:
          'Anyone can use the DMO Verification App without signing up! Share it ' +
          'with other pilots to encourage them to verify drone data on-site. It ' +
          'eliminates the risk of bad data. Did we also mention that it is FREE?'
      },
      {
        title: 'Android Tablet Support',
        description:
          'The Android app now looks beautiful on the Tablet form factor. This form ' +
          'factor provides more screen estate to comfortably execute a mission while ' +
          'keeping an eye on important parameters such as altitude, attitude and ' +
          'others.',
        image: {
          src: `${BASE_PATH}/projects-android-tablet.png`,
          height: 500,
          xsHeight: 200
        }
      },
      {
        title: 'Hourly and Daily Weather Forecast',
        description:
          'View more weather details such as the sunrise and sunset time, hourly ' +
          'forecast, daily forecast and more. Utilize the limited flying window ' +
          "effectively with advance planning. Don't be caught unprepared!",
        image: {
          src: `${BASE_PATH}/detailed-weather-android.png`,
          height: 500,
          xsHeight: 200
        }
      },
      {
        title: 'Look up Weather Anywhere',
        description:
          'As an extension to the detailed weather feature above, one can also ' +
          'look up weather at other places. Search for a location and view the ' +
          'detailed weather there. Period.'
      },
      {
        title: 'Export Flights Using the Verification App',
        description:
          'Flights can now be exported as a CSV or onto a map as a KML file using ' +
          'the Verification app. Great for submitting flight records to client.',
        image: {
          src: `${BASE_PATH}/flight-details-verification-app.png`,
          height: 500,
          xsHeight: 200
        }
      }
    ],
    improvements: [],
    bugs: [
      {
        title: 'Crash Due to no Assigned Projects',
        description:
          'Fixed a crash on the project screens when there were no projects ' +
          'assigned to the user.'
      },
      {
        title: 'Crash Due to Orientation Change of Projects Screen',
        description:
          'Fixed another crash on the project screen when refreshing the page ' +
          'while changing the orientation of the screen. Confusing? Well, it ' +
          'is fixed anyway.'
      },
      {
        title: 'More Android App Crashes',
        description:
          'A few other Android app crashes were also fixed in this release which ' +
          'should greatly improve the pilot experience. This was one of the focal ' +
          'points post a big July update.'
      }
    ],
    faqs: []
  },
  {
    version: '2022.7',
    caption: 'Biggest Android Update Since Launch!',
    product: 'Pilot app',
    date: '2022-07-18',
    features: [
      {
        title: 'A Brand New Home Page',
        description:
          'There is a lovely new landing page that has everything you would need ' +
          "from checking your drone's health, local weather, projects assigned " +
          'and more. App navigation has also been overhauled. Everything is just ' +
          "a tap away. We are sure you will love it! If you don't, we want to hear " +
          'about it. Share your feedback with us. ',
        image: {
          src: `${BASE_PATH}/home-screen.png`,
          height: 500,
          xsHeight: 200
        }
      },
      {
        title: 'Fly First. Sign up Later',
        description:
          "Don't you hate it when you are required to create an account before " +
          "being able to try out an app for the first time? We hate it too. That's " +
          'why we made it possible for you to explore the app and fly without ' +
          "signing up. Just install the app and go flying. You're welcome! " +
          'Have fun flying your drone!'
      },
      {
        title: 'Prominently Visible Drone Health',
        description:
          'The new drone card is right at the top of the home page and allows pilots ' +
          'to quickly glance at the most important parameters of their drone. They ' +
          'can also see a detailed break up of their drone health and understand its ' +
          'failures. Fly with confidence!',
        image: {
          src: `${BASE_PATH}/drone-card-v1.png`,
          height: 500,
          xsHeight: 200
        }
      },
      {
        title: 'Keep up With Industry News',
        description:
          'What is your favourite drone industry news outlet? How do you keep ' +
          'yourself updated? Allow us to help you. In the brand new home page, you ' +
          'will now be able to see a curated news section that will surely keep ' +
          'you on top of the recent developments in the drone industry. It will ' +
          'include everything from regulation updates to drone hardware announcements.',
        image: {
          src: `${BASE_PATH}/news-list-android.png`,
          height: 500,
          xsHeight: 200
        }
      },
      {
        title: 'Fleet Management',
        description:
          'Fleet management comes to the Android app. Add new users, drones and ' +
          'batteries to your organisation and assign them to projects and missions.',
        image: {
          src: `${BASE_PATH}/fleet-management-android.png`,
          height: 500,
          xsHeight: 200
        }
      },
      {
        title: 'Toggle Map Layers',
        description:
          'Toggle map layers to see exactly what you need on the map. Hide ' +
          'information that are not relevant. The base map layer also allows ' +
          'switching between map and satellite layers which can come handy in ' +
          'certain situations.',
        image: {
          src: `${BASE_PATH}/map-layers-android.png`
        }
      },
      {
        title: 'View Airspace Advisories',
        description:
          'Airspace advisories make their debut on the Android app to help pilots ' +
          'comply with local laws. Are you flying in a no-fly zone? Do note that ' +
          'these advisories are only a visual aid. They are not enforced by the app.',
        image: {
          src: `${BASE_PATH}/airspace-android.png`
        }
      }
    ],
    improvements: [
      {
        title: 'Streamlined Android Sign up Workflow',
        description:
          'The sign up workflow has been revamped to reduce effort and guide ' +
          'pilots towards either joining an existing organisation or creating a new ' +
          'one. This was particularly ambiguous for pilots resulting in them ' +
          'accidentally creating new organisations.'
      },
      {
        title: 'Scaled DJI Widgets in Tablet Form Factor',
        description:
          'The size of DJI widgets have been increased on the Tablet form factor. ' +
          'Sorry if you had to squint your eyes to read the drone state. While ' +
          'that would appear funny for a bystander, that was not our intention.'
      },
      {
        title: 'Update to DJI SDK 4.16.1',
        description:
          'The latest DJI SDK 4.16.1 is now used by the DMO Android app. This ' +
          'should fix a few crashes and improve the overall experience for the pilot ' +
          'running Android 12.'
      }
    ],
    bugs: [
      {
        title: 'A Multitude of Android App Crashes',
        description:
          'In the past few months, we observed several app crashes experienced by pilots ' +
          'on the Android app. Thanks to the timely feedback received from pilots, ' +
          'almost all of them have been fixed in this release! Thank you very much ' +
          'for your patience.'
      },
      {
        title: 'Corridor Mapping Waypoint Too Close Bug',
        description:
          'Fixed an issue related to the corridor mapping where pilots would see the ' +
          'error "The waypoint distance is too close!" This turned out to be a ' +
          'geometry bug introduced while generating missions. This bug has been ' +
          'eradicated.'
      },
      {
        title: 'Deleting User Documents Fails',
        description:
          'Pilots can upload their pilots licenses to their profile but were unable ' +
          "to delete it. Don't worry. We do not charge for file storage. Nonetheless, " +
          'this bug has been fixed. You can be at ease.'
      }
    ],
    faqs: []
  },
  {
    version: '2022.1',
    caption: 'Spotlight: Pilot Experience',
    product: 'Web Portal, Pilot & Verification App',
    date: '2022-01-03',
    announcements: [
      {
        message:
          'Important Announcement: We regret to inform that the <b>DMO iOS app</b> will stop receiving updates ' +
          'from here on and be <b>deprecated</b> on <b>March 1st 2022</b>! It is recommended to switch to the ' +
          '<a style="color: white" href="https://play.google.com/store/apps/details?id=com.skylarkdrones.dronemissionops"><b>Android app</b></a> ' +
          'as soon as possible to continue receiving new features and bug fixes.',
        icon: 'mdi-alert-circle',
        color: 'red darken-1'
      }
    ],
    features: [
      {
        title: 'Pilot Profile',
        description:
          'This release has been about improving the experience of the pilot starting with the ' +
          'pilot profile. Pilots can check their flying experience broken down into industries and ' +
          'mission types. The projects executed by the pilot are also available for inspection. Think ' +
          'of this as a pilot log book.',
        image: {
          src: `${BASE_PATH}/pilot-profile.png`
        }
      }
    ],
    improvements: [
      {
        title: 'See GCP Information by Clicking On It',
        description:
          'Users have a tendency to click on objects they can see on their screen. This is why we ' +
          'made the GCP marker clickable to view a popup with its details. Also, pilots can use this ' +
          'to navigate to the GCP marker, confirm its visibility and also use that area as a launch site.',
        image: {
          src: `${BASE_PATH}/gcp-popup.jpg`,
          height: 200
        }
      },
      {
        title: 'Photo Style Set to Standard Automatically',
        description:
          'Photogrammetry requires images to be sharp for better identification of objects at high zoom ' +
          'levels. To ensure this, the pilot app now sets the photo style to Standard automatically before ' +
          'executing a mapping mission.'
      },
      {
        title:
          'Camera Focus Mode and Target Set Automatically on DMO Pilot Android',
        description:
          'Aligning with the goal of simplifying the drone operations for the pilot, the DMO Android app ' +
          'now automatically sets the camera focus mode and target before executing the mission. Even if ' +
          'the focus mode was incorrect, the DMO app will correct it to ensure good quality focussed images.'
      },
      {
        title: 'Memory Optimised DMO Pilot iOS App',
        description:
          'The iOS app has been made slightly more performant by controlling its memory usage. This should ' +
          'make it more robust during prolonged usage.'
      },
      {
        title: 'Auto-Center Mini-Map to Drone',
        description:
          'When executing a mission, pilots frequently switch between the map and the camera feed view to ' +
          'monitor the health of the drone. While viewing the camera feed, the pilots can monitor the ' +
          "drone's position using the mini-map which now auto-centers on the drone continuously."
      }
    ],
    bugs: [
      {
        title: 'Mission Refresh Does Not Work Correctly on Geometry Update',
        description:
          "When the mission's geometry was updated on the Web Portal, the updated geometry would not " +
          'appear on the pilot apps despite performing a mission refresh. Somehow the geometry change ' +
          'was not considered as a priority and thereby ignored in certain rare cases. Awkward.'
      },
      {
        title: 'Live Camera Feed Appears Cut-Off',
        description:
          'The live camera feed was not being properly centered on the phone screen resulting in some of ' +
          'the footage being cut-off. This is now centered correctly. Period.'
      },
      {
        title: 'RTH Line Not Drawn on Mission Completion',
        description:
          'The RTH line which informs the pilot of the path the drone is taking to reach the home point ' +
          'was not being shown on mission completion. This bug has been finally fixed and tested to our ' +
          'satisfaction.'
      },
      {
        title:
          'Drone Captures Images While Rotating in Corridor Mapping Missions',
        description:
          'Corridors like highways, railway lines have turns and in these places the drone would ' +
          'capture images while rotating. This resulted in a few images having rotational blur. This has ' +
          'been fixed by asking the drone to be sequential in its actions i.e do one task at a time and ' +
          'sacrifice efficiency a teeny tiny bit.'
      },
      {
        title: 'Drone Captures 1st Image While Gimbal Still Rotating',
        description:
          'On reaching the first waypoint, the drone would capture the first image while the gimbal is still ' +
          'rotating to nadir (-90 degrees). Here we rotate the gimbal at launch way before the drone reaches' +
          'the first waypoint. The amount of attention required for small details like this is excruciating!'
      },
      {
        title: 'Low Battery Dialog Appears During RTH/Landing',
        description:
          'When the drone battery reaches the low battery threshold, a dialog is shown to the pilot asking what ' +
          'they would like to do (RTH, Continue Mission or Fly Manually). However, this dialog was also being ' +
          'shown when the drone was already performing a RTH manoeuvre which was unnecessary as the corrective ' +
          "action is already being performed by the pilot. Let's try to not confuse the pilot."
      },
      {
        title: 'Camera System Error Appears Frequently',
        description:
          'During mission upload, we noticed that the camera system error would appear frequently. It turns ' +
          'out that the DJI camera can only take in a couple of commands at any point in time. Give it too ' +
          'many instructions and it will complain in machine language i.e Camera System Error.'
      }
    ],
    faqs: []
  },
  {
    version: '2021.11',
    caption: 'Focal Point: Corridor Video Missions',
    product: 'Web Portal and Pilot app',
    date: '2021-11-10',
    features: [
      {
        title: 'Overhauled Corridor Video Mission Planning',
        description:
          'The corridor video mission planning workflow has been revamped based on ' +
          'the user feedback received. One can see mission previews, reverse ' +
          'flying direction, change mission overlap, set end of mission action etc. ' +
          'in the brand new dialog. It has a plethora of new options worth exploring.',
        image: {
          src: `${BASE_PATH}/revamped-corridor-video-generation.png`
        }
      },
      {
        title: 'Fine-Grained Corridor Video Mission Execution',
        description:
          'Pilots now have full control over the corridor video mission execution by ' +
          'being able to change the mission altitude, gimbal angle, drone speed etc. ' +
          'on site!'
      },
      {
        title: 'Set End of Mission Action',
        description:
          'Mission planners and pilots can now set the action the drone should perform ' +
          'on completing a mission. Supported actions include RTH (default), Hover and Land.'
      },
      {
        title: 'Set Terrain Follow Sensitivity',
        description:
          'Since its inception, terrain follow has improved the quality of the data ' +
          'captured and drone safety at the cost of slightly higher battery usage. ' +
          'Sometimes, data quality might not be the most important criteria. In ' +
          'order to account for these scenarios, support for setting the terrain ' +
          'follow sensitivity has been introduced! The mission planner can decide ' +
          'how accurately the drone should follow the terrain to suit his operational ' +
          'requirements.',
        image: {
          src: `${BASE_PATH}/terrain-follow-sensitivity.png`,
          height: 200,
          xsHeight: 150
        }
      }
    ],
    improvements: [
      {
        title: 'Support Uploading KMZ Files & Multi-Geometry Areas of Interest',
        description:
          'The area of interest upload module has been improved to support KMZ ' +
          'files and multi-geometry features. Do let us know if there are other ' +
          'friction points in your workflow you would like fixed!'
      },
      {
        title: 'Monitor SD Card Storage During Mission Execution',
        description:
          'Mission execution is now aborted if the SD card has run out of disk ' +
          "space. What's the point of mission execution if not to capture data?"
      }
    ],
    bugs: [
      {
        title: 'Android App Crash on Loading Invalid Profile Image',
        description:
          'Sometimes, the profile image returned by Google is malformed resulting in ' +
          'a crash when displaying this profile image. We now gracefully show you a ' +
          'stock image as a fallback. Hope you do not mind.'
      },
      {
        title: 'Android App Crash While Reordering Preflight Checklists',
        description:
          'Items that have a warning or an error are moved to the top to grab your ' +
          'attention. At times, a mix-up in the ordering occurs, and in those times of ' +
          'confusion, software just crashes. Period. We hopefully have resolved this ' +
          'for good.'
      },
      {
        title: 'Android App Shows Pairing Dialog in Offline Conditions',
        description:
          'The fleet pairing dialog was shown by the Android app in offline conditions ' +
          'which the pilot could not dismiss thereby disrupting operations on-field. '
      }
    ],
    faqs: []
  },
  {
    version: '2021.10',
    caption: 'Safety, Polish and Silence before the next big update!',
    product: 'Web Portal and Pilot app',
    date: '2021-10-08',
    features: [
      {
        title: 'Safe Mission Execution',
        description:
          'The DMO Pilot app now intelligently computes a safe path between the ' +
          'home location and the 1st waypoint and back home. This should account ' +
          'for any surprises in elevation differences encountered by the drone ' +
          'on its way to the mission and back home!',
        image: {
          src: `${BASE_PATH}/safe-mission-execution.png`
        }
      },
      {
        title: 'Push Notifications',
        description:
          'Pilots assigned to a team would now start getting notifications on their ' +
          'mobile device and be in the loop at all times. More events will be ' +
          'progressively sent as notifications in future app updates.'
      },
      {
        title: 'Sign-up Using Pilot App',
        description:
          'Pilots can now create a new account by signing up on the Pilot app. ' +
          'This is just the beginning of the journey where we empower the pilot to ' +
          'get more done with the mobile apps.'
      }
    ],
    improvements: [
      {
        title: 'Streamlined Sign-in and Sign-up Workflows',
        description:
          'The separate sign-in and sign-up buttons for Google were found to be ' +
          'confusing to users and have been combined into a single "Continue with ' +
          'Google" button that redirects users automatically depending on if they ' +
          'are a new or existing user.'
      },
      {
        title: 'Pilot App Updates to The Latest DJI SDK Release',
        description:
          'The latest DJI SDK 4.15.1 is now used by the DMO Pilot app on Android ' +
          'and iOS. This should fix a few crashes and improve the overall experience ' +
          'for the pilot.'
      }
    ],
    bugs: [
      {
        title: 'DMO Pilot App Complains About Damping Failure',
        description:
          'If you were wondering what damping failure means, you are not alone. It ' +
          'turns out the DJI SDK has certain rules regarding the corner radius of ' +
          'waypoints which when breached shows up as a cryptic damping failure.'
      },
      {
        title:
          'DMO Pilot App Complains About Mission Radius Being Unacceptable',
        description:
          'This is yet another scenario where the DJI SDK wants to play a guessing ' +
          'game. Every DJI drone comes with a <i>Maximum Flight Distance</i> setting, ' +
          'which is used to constrain the farthest distance the drone can fly from ' +
          'the home location. If your mission is longer than this limit, then this ' +
          '<i>distance</i> limit is breached resulting in the cryptic error message.'
      },
      {
        title: 'Video Recording Was Not Stopped When RTH is Triggered',
        description:
          'The Android app did not stop the video recording on RTH being triggered ' +
          'by the pilot or due to low battery. This is now done automatically by ' +
          'the app.'
      },
      {
        title: 'Camera Feed Was Distorted For DJI Phantom 4 Pro Series Drones',
        description:
          'The camera feed was getting distorted when flying a mission using a DJI ' +
          'Phantom 4 Pro series drone. This hardware specific bug has been fixed ' +
          'to our satisfaction and confirmed by a field test. We can imagine how ' +
          'annoying it was for pilots!'
      },
      {
        title: 'Fleet Pairing Dialog Shown in Offline Conditions',
        description:
          'The fleet pairing dialog was shown by the iOS app in offline conditions ' +
          'which the pilot could not dismiss thereby disrupting operations on-field. ' +
          'Sorry about that. We have squashed this bug for good.'
      },
      {
        title: 'Automatic Logout on iOS App in Some Situations',
        description:
          'Pilots were logged out when the iOS app failed to verify the user due to ' +
          'network issues. The logout criteria has been reworked to do that only when ' +
          'absolutely warranted.'
      },
      {
        title: 'A Multitude of Android App Crashes',
        description:
          'In the past month, we observed several app crashes experienced by pilots ' +
          'on the Android app. Thanks to the timely feedback received from pilots, ' +
          'almost all of them have been fixed in this release! Thank you very much ' +
          'for your patience.'
      },
      {
        title: 'Error Editing Missions of AOIs With Custom Elevation Source',
        description:
          'Due to an elevation validation issue caused due to using the wrong mission ' +
          'geometry, editing missions of AOIs with custom elevation source failed at ' +
          "times. A simple one-liner fixes this critical issue. That's the beauty of " +
          'software.'
      },
      {
        title:
          'Battery Health Was Not Fetched Correctly Due to Race Conditions',
        description:
          'Software and hardware talk at different rates. When the software asks the ' +
          'hardware for the battery health, it needs to wait for the hardware to ' +
          'respond. In this case, the software was reporting early that the hardware ' +
          'reported a null value.'
      }
    ],
    faqs: []
  },
  {
    version: '2021.8',
    caption: 'Polish, Performance & Papercuts',
    product: 'Web Portal, Pilot & Verification App',
    date: '2021-08-05',
    features: [],
    improvements: [
      {
        title: 'Performance Boost - Speed Thrills',
        description:
          'Automatic mission generation for area and corridor mapping projects have ' +
          'been sped up by a factor of <b>6x</b>! Considering that this is one of the ' +
          'most used feature on the DMO Web Portal, all users should see a visible ' +
          'speed up!'
      },
      {
        title: 'Mobile Responsive Sign-up Workflow & Welcome Wizard',
        description:
          'The sign-up workflow and the welcome wizard are important touch points ' +
          'for new users. Making this accessible on multiple form factors was an ' +
          'important milestone to hit.'
      },
      {
        title: 'Enable Elevation Heatmaps for Corridor AOIs',
        description:
          'The elevation heatmap was previously only available for polygon areas of ' +
          'interest. This has now been extended to corridor areas of interest for both ' +
          'mapping and video projects. Try it out during the feasibility check of ' +
          'your next project!',
        image: {
          src: `${BASE_PATH}/corridor-elev-heat-map.png`
        }
      },
      {
        title: 'Allow Renaming GCPs Individually',
        description:
          'A minor quality of life improvement that allows renaming of GCPs ' +
          'individually. Useful for naming critical GCPs differently to spot them ' +
          'easier.'
      },
      {
        title: 'Improved State Awareness on DMO Pilot',
        description:
          'The information snackbars are now shown more frequently with actionable ' +
          'messages where applicable to keep the pilots aware of the app state at all ' +
          'times and take corrective actions appropriately. It is the small things ' +
          'that trip pilots leading to a bad experience. And we are on top of it to ' +
          'make it better.'
      },
      {
        title: 'Switch Map Layers in Mission Generation Dialog',
        description:
          'Get better context of the area covered by the mission plans using the ' +
          'satellite layer when generating missions. It comes quite handy when ' +
          'planning missions over active mines.'
      },
      {
        title: 'Quality of Life Improvements on DMO Verification App',
        description:
          'Some nice papercut fixes to the verification app such as,' +
          '<ul>' +
          '<li>Show the newly created teams at the top</li>' +
          '<li>Make it easier to refresh the assigned teams list</li>' +
          '<li>Remove the now redundant post-process verification workflow</li>' +
          '</ul>'
      }
    ],
    bugs: [
      {
        title: 'DMO Pilot iOS App Crash Due To Missing Inventory',
        description:
          'Ouch! The DMO Pilot iOS app was crashing while trying to establish the ' +
          'pairing status of connected equipment when the inventory was not present ' +
          'locally. A hot-fix release was deployed at the speed of light.'
      },
      {
        title: 'Max. Drone Speed Not Limited in Mission Generation Dialog',
        description:
          'The max. drone speed is constrained by the camera sensor, front overlap ' +
          'and a couple of factors which were not taken into account while setting ' +
          'the default drone speed in the mission generation dialog. This lapse ' +
          'has been fixed.'
      },
      {
        title: 'Mission Generation Failed For Modified Area of Interest',
        description:
          'On editing an area of interest, mission generation would fail due to an ' +
          'invalid server state. Sorry about that! It is all good now.'
      },
      {
        title: 'Minor Sample Project Fixes',
        description:
          'Sample projects created for new users had a few incorrect attributes which ' +
          'might show up as oddities. As mentioned above, minor issues, which are ' +
          'nonetheless fixed.'
      }
    ],
    faqs: []
  },
  {
    version: '2021.7',
    caption: 'Fleet Health, Easier New User Flow & Lots More',
    product: 'Web Portal, Pilot & Verification App',
    date: '2021-07-07',
    features: [
      {
        title: 'Monitor Drone Usage And Failures',
        description:
          'Drone failures and air time are now surfaced in the drone details dialog. ' +
          'This information is collated and sent by the DMO Pilot app which tracks ' +
          'these metrics during mission execution.',
        image: {
          src: `${BASE_PATH}/drone-health.png`,
          height: 300
        }
      },
      {
        title: 'Monitor Battery Health',
        description:
          'Keep an eye out on the battery health attributes like discharge cycles, ' +
          'current max capacity and usage time on the DMO Web Portal. This will help ' +
          'quickly identify failing batteries and avoid accidental usage.',
        image: {
          src: `${BASE_PATH}/battery-health.png`,
          height: 150,
          xsHeight: 75
        }
      },
      {
        title: 'Upload Project Related Files',
        description:
          'Operations Managers can now upload project related files like scope of ' +
          'work, work orders etc. and manage them alongside missions, areas of ' +
          'interest etc. All project related information accessible from one place.',
        image: {
          src: `${BASE_PATH}/project-files.png`,
          height: 225,
          xsHeight: 175
        }
      },
      {
        title: 'Allow Pilots to Pair Their Equipment using DMO Pilot',
        description:
          'Pilots can now pair drones and batteries with the existing fleet. ' +
          'This will enable the DMO Pilot app to sync critical information about the ' +
          'equipment and improve the intelligence of existing features like team ' +
          'creation in the near future.'
      },
      {
        title: 'Corridor Video Mission Execution Support on DMO Pilot Android',
        description:
          'Corridor video mission execution was supported on DMO Pilot iOS. This ' +
          'feature has now been brought to the Android app as well.'
      },
      {
        title: "Set Company's Operating Location",
        description:
          'Admins of a company can now set its operating location which would be ' +
          'used in different parts of the platform to show relevant locations. We ' +
          'will be adding more company level settings like changing measurement units, ' +
          'ability to set a company logo etc. in the near-future.',
        image: {
          src: `${BASE_PATH}/company-operating-location.png`
        }
      }
    ],
    improvements: [
      {
        title: 'Improve The New User Journey',
        description:
          'New users will now see a gorgeous welcome wizard that helps them set up ' +
          'their company and get acquainted with DMO. We also add a few sample ' +
          'projects to get the ball rolling and demonstrate some of the salient ' +
          'features of the platform.'
      },
      {
        title: 'Speed Up Execution of Terrain Aware Missions by 20%',
        description:
          'With the same exact mission configuration, the DMO Pilot app now executes ' +
          'terrain aware missions 20% faster! Did we break any laws of Physics? No, ' +
          'it turns out that we can ensure the drone does not momentarily stop as it ' +
          'flies over waypoints at different altitudes.'
      },
      {
        title: 'Reworked Add Drone and Battery Dialogs',
        description:
          'Polished the add drone and battery dialogs to bring it inline with the ' +
          'newly created ones. Minor issues fixed in the process.'
      },
      {
        title: 'Return Battery Models in Alphabetical Order',
        description:
          'Just a minor paper cut fixed where the battery models are now returned in ' +
          'the alphabetical order to make the order more logical and predictable.'
      }
    ],
    bugs: [
      {
        title: 'User Not Signed Out When Logged Out of Their Google Account',
        description:
          'Have you noticed that on the Web Portal, the loading indicator keeps on ' +
          "loading? That's probably because you were signed out of your Google " +
          'account. This happens all the time. However, we needed to sign you out of ' +
          'DMO as well to get you to relogin properly.'
      },
      {
        title: 'Mission Progress Was Not Reset in a Duplicated Project',
        description:
          'Projects which were duplicated did not have their mission progress reset. ' +
          'This may have caused confusion at times. The bug has been fixed to our ' +
          'satisfaction.'
      },
      {
        title: 'Flown Progress Plotted For Completed Missions',
        description:
          'In our quest to show you real-time mission progress, we overdid our job ' +
          'by plotting the flown progress again for completed missions which was ' +
          'already denoted using a green fill. We got carried away.'
      },
      {
        title: 'Fix Verification App Crash on Toggling Video Flights Validity',
        description:
          'Pretty much what the title reads. Quite a rare event really, but ' +
          'nonetheless, a crash is a crash and we have fixed it.'
      },
      {
        title: 'Fix Video Flight Polygons Not Generated In Some Scenarios',
        description:
          'Video flight polygon generation was failing in some scenarios due to ' +
          'a bug in one of the dependent libraries. A suitable workaround has been ' +
          'found to resolve the bug at all costs.'
      }
    ],
    faqs: []
  },
  {
    version: '2021.6',
    caption: 'Improved Fleet Management & Flight Records',
    product: 'Web Portal & Pilot',
    date: '2021-06-01',
    features: [
      {
        title: 'Upload Pilot Licenses & Certificates',
        description:
          'Pilots can now upload their licenses and certificates against their ' +
          'user profile and manage them on the DMO Web Portal. In the near ' +
          'future, pilots will be able to quickly access it from within the ' +
          'DMO Pilot app.',
        image: {
          src: `${BASE_PATH}/pilot-license-upload.png`
        }
      },
      {
        title: 'Create Flight Records For All Drones',
        description:
          'Are you unable to track your operations due to the drone ' +
          'model not supported yet on the DMO platform? In the interim, ' +
          'log flights by entering them yourself on the Web Portal and track ' +
          'drone and battery cycles. Collaborate with us to get your drone ' +
          'supported.'
      },
      {
        title:
          'Preliminary Support for DJI Inspire 2, Spark, Mavic Air, Mavic Mini ' +
          '& SenseFly eBee Plus',
        description:
          'Mission planning support for more DJI and SenseFly drones have been ' +
          'added. Execution and data verification support are planned for ' +
          'subsequent releases.'
      },
      {
        title: 'Bulk Import Drones via CSV',
        description:
          'Add your entire fleet of drones in one click using the bulk import ' +
          'via CSV feature!'
      }
    ],
    improvements: [
      {
        title: 'More Detailed Flight Records',
        description:
          'Flight records have been updated to capture airspace classes, observer ' +
          'and detailed weather information such as the temperature, cloud ' +
          'cover, wind speed and wind direction.'
      },
      {
        title: 'Show Flight Markers in the Operations Footprint',
        description:
          'The flights heatmap in the Operations Footprint has been replaced ' +
          'by flight markers that can be clicked to view quick details about ' +
          'the flight. Dive deep into the Operations Footprint to get a better ' +
          'sense of your operations across the country.'
      },
      {
        title: 'Alphabetically Ordered Camera and Drone Models',
        description:
          'Camera and drone models are now listed in alphabetical order to ' +
          'make it easier to search for a model. It is a minor paper cut that ' +
          'was present in frequently used workflows.'
      },
      {
        title: 'Save Pilot Inputted Elevations Locally on DMO Pilot Android',
        description:
          'Previously, pilots could enter the elevation of the home location if ' +
          'it was not available in cases where the home location is far away ' +
          'from the area of interest. This pilot entered elevation is now ' +
          'saved locally for reuse in subsequent missions.'
      }
    ],
    bugs: [
      {
        title: 'Team Creation Fails in Certain Conditions',
        description:
          'Creating a team would fail in certain scenarios where the internal ' +
          'serial id conflicts with existing ones. Nothing much the user could ' +
          'do in that situation. But we can. We fixed the bug.'
      },
      {
        title: 'Ops. Manager Exclusive Features Not Hidden For Pilots',
        description:
          'Certain map operations like adding an area of interest, planning ' +
          'missions and other project management features which were exclusive ' +
          'to Operations Manager and Admin were visible to pilots and planners ' +
          'despite the actions not working for them. They have been hidden to ' +
          'avoid any confusion.'
      },
      {
        title: 'Home Elevation Fetch on DMO Pilot Android Failed Sometimes',
        description:
          'Due to a race condition where the home location is obtained before ' +
          "the elevation grid is cached locally, the home location's elevation " +
          'could not be fetched in offline mode. This rare bug has been stomped.'
      },
      {
        title: 'Persistent Error Notifications on DMO Pilot Android',
        description:
          'Previously shown error notifications when modifying the course ' +
          'angle on DMO Pilot Android are now hidden if the new set of ' +
          'generated waypoints are valid.'
      }
    ],
    faqs: []
  },
  {
    version: '2021.5',
    caption: 'Fly • Track • Be Omniscient',
    product: 'Web Portal, Pilot & Verification App',
    date: '2021-05-06',
    features: [
      {
        title: 'Real-Time Tracking of On-Field Operations',
        description:
          'Tracking on-field operations is a bane to operation managers as ' +
          'they have to resort to frequent follow ups with the pilot while ' +
          'attending to other tasks. No more! The DMO Pilot app will sync ' +
          'the mission status in real-time! Track the flown areas of a ' +
          'mission as they are flown by the pilot.'
      },
      {
        title: 'Capture User & Drone Location Information',
        description:
          'Set the operating location of users and drones to plan projects ' +
          'more efficiently by assigning them to pilots closest to the site. ' +
          'This information will be integrated more deeply into the workflows ' +
          'surrounding team assignment, planning logistics and more.'
      },
      {
        title: 'Spatial View of Users, Drones and Flights',
        description:
          'Get a national view of your users, drones and flights in a single ' +
          'place "The Operations Footprint". Leverage the powerful filters ' +
          'provided to look at the data from different angles.',
        image: {
          src: `${BASE_PATH}/spatial-view.png`
        }
      },
      {
        title: 'Autel Evo II Pro Drone Support',
        description:
          'Mission planning and data verification support for the Autel Evo ' +
          'II Pro drone model has been added. Support for other Autel drones ' +
          'will be added in subsequent updates.'
      },
      {
        title:
          'Preliminary Support for DJI Matrice 300 RTK, Matrice 600 & ' +
          'Matrice 600 Pro',
        description:
          'Mission planning support for the DJI enterprise drones have been ' +
          'added. Execution and data verification support are planned for ' +
          'subsequent releases.'
      },
      {
        title: 'Bulk Import Users via CSV',
        description:
          'Tired of adding users one by one? Try out the bulk import feature ' +
          'where you can add your entire fleet in one click.'
      }
    ],
    improvements: [
      {
        title: 'View User & Drone Details',
        description:
          'View more details about a user or drone by clicking on their respective ' +
          'cards. This will serve as the base for other information such as pilot ' +
          'licenses, metrics and activity timeline.'
      },
      {
        title: 'Notify Users Added to the Company',
        description:
          'Users added to your company will now be notified by email with ' +
          'set up instructions for DMO Web Portal, Pilot and the Verification ' +
          'app.'
      }
    ],
    bugs: [
      {
        title: 'Feature Geometries Not Plotted in Team Details Page',
        description:
          'Due to a minor behavioral change, the areas of interest, missions ' +
          'and gcps were not being plotted on the map in the team details page.' +
          'This bug has been squashed.'
      }
    ],
    faqs: []
  },
  {
    version: '2021.4',
    caption: 'Revamped Verification Workflow & Smarter Android',
    product: 'Web Portal, Pilot & Verification App',
    date: '2021-04-05',
    features: [
      {
        title: 'Revamped Verification App Home Screen',
        description:
          'The home screen of the verification app has been revamped to provide a ' +
          'streamlined verification workflow to pilots. Amidst a chaotic and hectic ' +
          'on-field experience, the new home screen gets out of the way and allows ' +
          'pilots to verify the data without any hassle. Try it out and let us ' +
          'know what you think!',
        image: {
          src: `${BASE_PATH}/DMO-Verification.png`,
          height: 550,
          xsHeight: 300
        }
      },
      {
        title: 'Support for Apple Sign-in',
        description:
          'We started of with Google Sign-in to help the user avoid creating yet ' +
          'another account and face the hassle of forgetting their password. We ' +
          'have expanded this interoperability with the addition of Apple ' +
          'Sign-in/up. We are committed to reducing your overhead where possible.'
      },
      {
        title: 'DMO Pilot Android Feature Parity with iOS',
        description:
          'Since the debut of DMO Pilot Android in the previous update, the focus ' +
          'has been on hitting feature parity with its iOS counterpart. With this ' +
          'update, support for corridor mapping mission execution, resetting ' +
          'missions and storing home coordinates in the image metadata has landed ' +
          'in the Android build.'
      }
    ],
    improvements: [
      {
        title: 'Performance Boost',
        description:
          '<ul>' +
          '<li>Sped up the sign in/up by 40% for every new designation added to a user</li>' +
          '<li>Sped up the deletion of projects by 90% in some cases</li>' +
          '</ul>'
      },
      {
        title: 'Improved DMO Pilot Android App Registration UX',
        description:
          'All mobile apps that support DJI drones are required to register with DJI ' +
          'on first launch without which one would not be able to connect to the ' +
          'drone. The UX surrounding this workflow has been improved to make it ' +
          'more intuitive and transparent to a new user.'
      },
      {
        title: 'Spring Clean up of Verification App',
        description:
          'Legacy features such as the equipment and pilot readiness checklists ' +
          'have been removed to bring the app more in line with its intended goal ' +
          'which is to help with data management and verification on field. The ' +
          'development team is trigger-happy when it comes to removing code and ' +
          'providing a focussed experience.'
      }
    ],
    bugs: [
      {
        title: 'Crash on Providing Incorrect P4RTK Images Folder Path',
        description:
          'Unlike other DJI workflows, the P4RTK data download requires the user ' +
          'to select the exact folder that contains the RTK flights. If the ' +
          'user selected an incorrect folder, the verification app would crash. ' +
          'This has been fixed by gracefully invalidating the selected folder.'
      },
      {
        title: 'Flight Verification Stops Abruptly After Overlap Check Failure',
        description:
          'If the overlap check was skipped for whatever reason, the aspect ratio ' +
          "and pitch checks were also skipped! The saying, don't blindly follow the " +
          'the crowd is apt at describing this bug. Regardless, this issue has ' +
          'been put to rest.'
      },
      {
        title: 'Invalid Project Search Queries Fail Without Feedback',
        description:
          'Project search failures are now handled more gracefully by informing ' +
          'the user rather than just outright failing without any meaningful ' +
          'notification.'
      },
      {
        title: 'Camera Model Not Recognized on DMO Pilot Android',
        description:
          'Certain camera models were not being detected correctly resulting ' +
          'in the user seeing unrecognized camera model error in the pre-flights ' +
          'screen. This bug has been squashed to our satisfaction.'
      }
    ],
    faqs: []
  },
  {
    version: '2021.2',
    caption: 'DMO Pilot, Say Hello to the Droids',
    product: 'Web Portal, Pilot & Verification App',
    date: '2021-02-10',
    features: [
      {
        title: 'DMO Pilot Supports Android',
        description:
          'The Pilot app is now on the <a href="https://play.google.com/store' +
          '/apps/details?id=com.skylarkdrones.dronemissionops">Google Play Store</a> ' +
          'and supports the same 3 click mission execution that you have come to ' +
          'love about our iOS application. For the first release, it only supports ' +
          'area mapping mission execution. Corridor mapping and video execution ' +
          'will be added in subsequent releases.',
        image: {
          src: `${BASE_PATH}/dmo-pilot.webp`,
          height: 300
        }
      },
      {
        title: 'New Projects Kanban (Column) View',
        description:
          'A new column view has been added to the projects page to better depict ' +
          'the state of projects currently being undertaken. The previous stacked ' +
          'view is still available for use if you prefer that.',
        image: {
          src: `${BASE_PATH}/kanban-view.png`
        }
      },
      {
        title: 'Invalid Image Filtering During Data Copy',
        description:
          'Ground shots, images with bad metadata likely due to faulty sensors are  ' +
          'now filtered out while copying the data from the drone. Also, any flight  ' +
          'found with very few images post this filtering will be marked as invalid ' +
          'automatically.'
      }
    ],
    improvements: [
      {
        title: 'Performance Boost',
        description:
          '<ul>' +
          '<li>Sped up the loading of the personnel page by 70%.</li>' +
          '<li>Sped up the querying of drones by 45%.</li>' +
          '<li>Sped up the querying of sensors by 35%.</li>' +
          '<li>Sped up the querying of batteries by 55%.</li>' +
          '</ul>'
      },
      {
        title: 'View UTM Advisory Details on Clicking Layer on Map',
        description:
          'Find out more about an UTM Advisory by clicking on it on the map.'
      },
      {
        title: 'Draw Areas of Interest Faster on the Map',
        description:
          'On creating a new project, one can start drawing on the map right after ' +
          'setting the mission type. Previously, this was a slightly more cumbersome ' +
          'process.'
      },
      {
        title: 'Autocompletion of Team Assignment & Data Acq. Start Statuses',
        description:
          'The project statuses such as Team Assignment and Data Acq. Start are now ' +
          'autocompleted on creating the first team and flight respectively.'
      },
      {
        title: 'Better Introduction to Project Feasibility Check Feature',
        description:
          'New users will go through an introductory wizard to learn about the project ' +
          'feasibility check feature that was added in the previous update.'
      },
      {
        title: 'Toggleable Map Buttons',
        description:
          'Improved the intuitiveness of the add GCPs and bulk select mission tools ' +
          'by changing them into toggle buttons which can be used to exit these ' +
          'workflows. Previously, one had to know that the Esc keyboard key could be ' +
          'used to exit them.'
      },
      {
        title: 'Sort All Projects in Reverse Chronological Order',
        description:
          'The projects view will now list projects in reverse chronological order ' +
          'i.e. newly created projects and projects recently updated would appear ' +
          'at the top.'
      },
      {
        title: 'More Accurate Progress and Goal Calculation',
        description:
          'The behind the scenes calculation of the project goal and progress has ' +
          'been made more accurate and robust. We will monitor the solution this month ' +
          'and if it works reliably, we will use it to autocomplete the data acq. ' +
          'complete status appropriately.'
      }
    ],
    bugs: [
      {
        title: 'Stale Flying Height After Editing GSD Using +/- Buttons',
        description:
          'Minor visual bug where the flying height label was not being updated ' +
          'on changing the GSD using the +/- buttons. Rest assured, mission execution ' +
          'was not affected by this bug.'
      },
      {
        title: 'Flight Time Shown in UTC in Today Card',
        description:
          'In the new project details page, the today card which shows the flight ' +
          'launch and land time was showing the time in UTC when everywhere else it ' +
          'was shown in local time. This has been corrected to avoid confusion and ' +
          'maintain consistency.'
      },
      {
        title: 'User Could Be Locked Out by Retiring Himself',
        description:
          'A user could accidentally retire himself on the Web Portal and be ' +
          'locked out in the process. A safety guard has been added to prevent this ' +
          'in the future.'
      },
      {
        title: 'Vague Error Message on Login Failure Due to Blocked Cookies',
        description:
          'A login failure due to cookies blocked by the browser is now more clear ' +
          'and actionable by informing the user on what specifically the issue is ' +
          'and how to fix it. '
      },
      {
        title: 'Any User Could Upgrade His Role or Edit Other Users',
        description:
          'Moving forward, only Operation Managers and Admin will be able to modify ' +
          `other users and upgrade roles to Operations Manager or Admin level. ` +
          'Pilots and planners are restricted from doing this.'
      },
      {
        title: 'Projects Search Broken For Projects in Feasibility Stage',
        description:
          'Project search results accidentally excluded projects in the feasibility ' +
          'stage. This bug has been squashed.'
      },
      {
        title: 'Long Progress and Goal Values Due to Too Many Decimal Places',
        description:
          'Project progress and goal values are now truncated to 2 decimal places ' +
          `to avoid long strings. Don't worry, we are not skimping on accuracy.`
      },
      {
        title: 'Phone Numbers with Characters (+, -) Broke Editing Users',
        description:
          'This is embarrassing but phone numbers with characters like +,- etc. broke ' +
          'editing users. The user creation workflow was not affected by this bug. ' +
          'We have fixed this bug to our satisfaction.'
      },
      {
        title: 'Invalid Flights Were Left Out of the Project Analytics',
        description:
          'Flights whether valid or invalid should contribute to the total project ' +
          'analytics as they were still executed by the pilot.'
      },
      {
        title:
          'Upcoming Projects Were Placed In The Older Than 3 Months Category',
        description:
          'When viewing projects grouped by date, the upcoming projects were being ' +
          'incorrectly placed in the Older Than 3 Months category rather than the ' +
          'upcoming category. If you have checked out the other projects grouping ' +
          'types, you should. They are quite helpful.'
      }
    ],
    faqs: []
  },
  {
    version: '2021.1',
    caption: 'New identity, feasibility check & lots more',
    product: 'Web Portal, Pilot & Verification App',
    date: '2021-01-05',
    features: [
      {
        title: 'Rebranded to Drone Mission Ops',
        description:
          'Drone Mission Control (DMC) is now Drone Mission Ops (DMO). Visit us ' +
          'at <b><a href=https://dronemissionops.com>dronemissionops.com</a></b>. ' +
          'New name, new website, new chapter. Be excited for us. We definitely are.'
      },
      {
        title: 'Revamped Project Details Page',
        description:
          'Look out for the new immersive and engaging project details page. It ' +
          'is better than the old design in every possible way. Improved workflow, ' +
          'radically better performance, compact design and lots more. It is hard ' +
          'to put it in words. Experience it!'
      },
      {
        title: 'Conduct Project Feasibility Checks',
        description:
          'At Skylark Drones, we start every project with a feasibility check ' +
          'which involves checking airspace advisories, terrain variations, ' +
          'accessibility and other general site assessments. This helps get a ' +
          'better understanding of the project before accepting it. The new ' +
          'project creation workflow starts with a feasibility check. Watch this ' +
          'space closely for more improvements.'
      },
      {
        title: 'View Airspace Advisories',
        description:
          'Airspace information is required for better spatial awareness during ' +
          'mission planning to ensure compliance with local laws. Is your area ' +
          'of interest in a no-fly zone? Get answers to these questions using ' +
          'the new airspace advisories feature. We have taken the first step with ' +
          'more features on the way.'
      },
      {
        title: 'Redesigned Map Features on Pilot App',
        description:
          'The Pilot app now sports a cleaner and a less busier map to reduce ' +
          'information overload and interface fatigue. Track mission execution ' +
          'with less effort. Pilots, you are welcome.'
      },
      {
        title: 'Bulk Delete Project or AOI GCPs',
        description:
          'GCPs can now be deleted in bulk on the AOI and project level. ' +
          'These small usability tweaks build up to a good user experience.'
      }
    ],
    improvements: [
      {
        title: 'Performance Boost',
        description:
          '<ul>' +
          '<li>Sped up the loading of the projects grid by 75%.</li>' +
          '<li>Sped up the projects search feature to make it almost instant! ' +
          'No annoying lag while you type.</li>' +
          '<li>Sped up the plotting of flights by 80%.</li>' +
          '<li>Sped up the plotting of missions by 40%</li>' +
          '<li>Sped up the plotting of areas of interest by 65%</li>' +
          '<li>Sped up the toggling of map layers to make it instant regardless ' +
          'of how many features are present.</li>' +
          '<li>Reduced size of image assets by 70% to improve image load ' +
          'times throughout the site.</li>' +
          '</ul>'
      },
      {
        title: 'Reduced Personnel Page Clutter',
        description:
          'Retired personnel are now hidden by default. Rest assured, their ' +
          'visibility can be toggled on demand.'
      }
    ],
    bugs: [
      {
        title: 'Incorrect Redirection to 403 Page on Browser Popup Block',
        description:
          'Users were incorrectly redirected to the 403 (Access Forbidden) page ' +
          'when the Google Sign-in popup was blocked by the browser. Hope it did ' +
          'not scare you away. The redirection has been replaced with a descriptive ' +
          'error to help you take action.'
      },
      {
        title: 'Incorrect Corridor Mapping Progress Calculation',
        description:
          'Units mismatch between the progress and goal resulted in incorrect ' +
          'progress percentages. This bug has been stomped.'
      }
    ],
    faqs: []
  },
  {
    version: '2020.12',
    caption: 'String of Critical Fixes Mixed with Features',
    product: 'Web Portal, Pilot & Verification App',
    date: '2020-12-01',
    features: [
      {
        title: 'Revamped Mission Assignment Workflow',
        description:
          'We heard you say that mission assignment is easy to forget and difficult ' +
          "for projects with large number of missions. That's why we have gone " +
          'ahead and revamped it. Multiselect groups of missions while at the same ' +
          'time having discrete control over individual missions.'
      },
      {
        title: 'More Transparent Flight Verification Workflow',
        description:
          'A brand new dialog that shows all the verification checks that will be ' +
          'done along with action points for checks disabled due to missing ' +
          'prerequisites. This should make it more clear to the pilot on the next ' +
          'course of action.'
      }
    ],
    improvements: [
      {
        title: 'Streamlined User Login Flow',
        description:
          'Did you hate having to sign in everytime you opened the DMC Web Portal? So ' +
          'did we. This has been improved to provide a seamless user sign in process. ' +
          'Sign in once and forget about it. Psst..we are also working on adding ' +
          'other sign-in providers.'
      },
      {
        title: 'Change Order of Project Activity Events',
        description:
          'Long running projects resulting in large amount of project activity that ' +
          'you need to scroll through before adding a comment? Rings a bell? Hopefully ' +
          'not. Project activity is now shown in the reverse chronological order ' +
          '(most recent first) by default. You can also change the order to suit ' +
          'your preferences.'
      },
      {
        title: 'Performance Boost',
        description:
          '<ul>' +
          '<li>Sped up the unit mission plans sync to DMC Pilot by about 90%!</li>' +
          '<li>Sped up auto-mission planning in projects wth large number of ' +
          'areas of interest.</li>' +
          '</ul>'
      },
      {
        title: 'New User Improvements',
        description:
          'Simplified the navigation sidebar to reorganize it based on frequent ' +
          'usage. The projects page is the default landing page. The home feeds ' +
          "page has been removed until it has more to offer. Don't worry, it will " +
          'make a comeback in the future.'
      },
      {
        title: 'Specific Action Suggestion on Low Disk Space during Data Copy',
        description:
          'The DMC Verification app is now more specific with how much disk space ' +
          'to clear up to copy the drone data to your system in the event of low ' +
          'disk space.'
      }
    ],
    bugs: [
      {
        title: 'Infinite Loop While Auto-Mission Planning',
        description:
          'This bug eluded us for sometime as it manifested in a different form ' +
          'and hit our servers hard everytime it surfaced. This would also result ' +
          'in our website being unresponsive for a few minutes. Happy to put the bug ' +
          'at its place below the ground level.'
      },
      {
        title: 'Rare Infinite Loop While Disabling Terrain Aware',
        description:
          'In a very specific scenario where terrain aware is disabled while editing ' +
          'a mission with high terrain variations and a low GSD, an infinite loop ' +
          'bug occurs when trying to save the mission. Hopefully, this marks an end ' +
          'to these kind of bugs.'
      },
      {
        title: 'Incorrect Flying Height After Editing Terrain Follow Missions',
        description:
          'Editing a terrain follow mission using DMC Pilot would result in an ' +
          'incorrect waypoint flying height being set. This critical bug was spotted ' +
          'thanks to pilot feedback and fixed immediately.'
      },
      {
        title: 'Editing Mission Name Erased the Elevation Source Property',
        description:
          'Editing a mission name after it has been created would result in the ' +
          'elevation source attribute being erased thereby affecting the mission ' +
          'execution by DMC Pilot.'
      },
      {
        title: 'Flight Verification Crash after Removing Project GCPs',
        description:
          'The verification app crashes when performing flight verification of a ' +
          'project where GCPs were added and later removed. Simple fix for a ' +
          'critical issue.'
      },
      {
        title: 'Last Stopped Drone Position not Recorded in Some Situations',
        description:
          "Remember the 2020.8 release where we upgraded DMC Pilot's memory to " +
          'remember the last stopped drone position? Well, we missed one scenario ' +
          'where it failed to record the position resulting in pilots seeing a ' +
          'strange order of waypoints when resuming a mission. Squashed this one ' +
          'as well.'
      },
      {
        title: 'Gaps in Auto-Mission Planned Areas of Interest',
        description:
          'In certain rare cases, there would be mission plans missing in certain ' +
          'places in the areas of interest where they were autogenerated. We tracked ' +
          'the source of the bug and stomped it.'
      },
      {
        title:
          'Invalid Flight Geometries Prevented Other Flights from Being Plotted',
        description:
          'In the event, a flight does not have a valid geometry, it would stop the ' +
          'DMC Portal from plotting other valid flights as well. This was due to a ' +
          "regression introduced in the previous month's update."
      },
      {
        title: 'Features Not Plotting on Unit Details Page',
        description:
          'The unit details page would be moody and not plot the features by default ' +
          'on opening the page. Instead, you would have to coax it by toggling the ' +
          'layer on and off to see them. That has been fixed.'
      },
      {
        title: 'Failure to Send Welcome Email on Successful Sign-up',
        description:
          'There was a small window where the welcome email was not sent on a ' +
          'successful sign-up. Sorry about that.'
      }
    ],
    faqs: []
  },
  {
    version: '2020.11',
    caption: 'An Amalgamation of Features & Improvements',
    product: 'Web Portal, Pilot & Verification App',
    date: '2020-11-03',
    features: [
      {
        title:
          'Corridor Mapping Auto-Mission Planning, Execution & Verification',
        description:
          'Have you had the requirement to survey kilometers long roads, railway ' +
          'lines, pipelines? You can now mission plan these corridors quickly and ' +
          'with full control over the mission parameters. Like with other mission ' +
          'types, we hope you enjoy <i>generating</i> missions in 3 easy steps. It ' +
          "doesn't get any easier."
      },
      {
        title: 'Search Location Map Tool',
        description:
          'The new search location tool will allow the user to quickly pan into ' +
          'any location on the map and start drawing areas of interest and missions ' +
          'there rapidly. It supports WGS84 coordinates and user friendly addresses.'
      },
      {
        title: 'New User Orientation Helpers',
        description:
          '<ul>' +
          '<li>Pilots logging into DMC Pilot for the first time are shown a nice ' +
          'walk through of the important features to help them get acquainted with ' +
          'DMC Pilot quicker.</li>' +
          '<li>Promoted discovery of DMC Pilot and DMC Verification App by adding ' +
          'installation links on the landing page, hinting the user when appropriate ' +
          'to switch to the other apps for mission execution and verification.</li>' +
          '<li>Simpler project details page with more emphasis on important actions ' +
          'like mission planning and unit creation to reduce cognitive overload ' +
          'and direct the user towards the appropriate action.</li>' +
          '<li>Simpler DMC Verification App home screen, again, to reduce cognitive ' +
          'overload and direct the user towards the important actions.</li>' +
          '<li>Sample GCP & Custom Elevation CSV files are now shown to help the ' +
          'the new user upload data in the correct format.</li>' +
          '<li>Set sensible constraints to the areas of interest size and mission ' +
          'overlap parameter to prevent users from accidentally setting absurdly ' +
          'high values.</li>' +
          '</ul>'
      },
      {
        title: 'Report DMC Pilot Issues via DMC Pilot',
        description:
          'Was that a tongue twister? Anyways, pilots can now report bugs from ' +
          'within the app. This would automatically attach the app log and other ' +
          'relevant information to help us fix the issue without bothering you ' +
          'for technical details.'
      }
    ],
    improvements: [
      {
        title: 'Performance Boost',
        description:
          '<ul>' +
          '<li>Area of interest upload times have been sped by almost 8X! ' +
          'Honestly, this feature has received more attention than originally ' +
          'planned in the form of various style improvements as well.</li>' +
          '<li>The project details and mission planner page network usage on ' +
          'initial load has been reduced by 50%.</li>' +
          '<li>Submitting comments with tagged users is now almost instant!</li>' +
          '</ul>'
      }
    ],
    bugs: [
      {
        title: 'Blurry Images due to Drone Rotation',
        description:
          'In some situations, the images at the edges of the mission were found ' +
          'to be blurry due to the drone capturing those images while rotating. ' +
          'It seems like drones are also bad with multitasking. This has been fixed ' +
          'by maintaining the same drone heading throughout the mission.'
      },
      {
        title: 'Latest DJI SDK Release Triggers DMC Pilot Crash',
        description:
          'The DJI SDK release 4.13.1 was causing DMC Pilot and other DJI apps to ' +
          'crash when the pilot switches between applications. This has been fixed ' +
          'by reverting to an earlier release of the DJI SDK until our DJI overlords ' +
          'release an official fix.'
      },
      {
        title: 'DMC Pilot Login Indicator is Shown Indefinitely',
        description:
          'In rare scenarios, DMC Pilot would be stuck in the login screen. This ' +
          'critical bug has been squashed to our satisfaction.'
      }
    ],
    faqs: []
  },
  {
    version: '2020.10',
    caption: 'Auto-Mission Planning is the new Norm',
    product: 'Web Portal, Pilot & Verification App',
    date: '2020-10-04',
    features: [
      {
        title: 'Area Mapping Auto-Mission Planning',
        description:
          'Feature highlight of this update! Planning mapping missions for large AOIs ' +
          'are now much simpler and can be done with minimal effort. Select the ' +
          "AOI, set parameters and click generate. That's it. You can generate " +
          'terrain follow missions for 50,000 acres in less than a minute!'
      },
      {
        title: 'Draw & Modify AOIs on DMC Web Portal',
        description:
          'Until now, 3rd party software like Google Earth was required to add AOIs ' +
          'to a project. You can now draw and modify AOIs from within the Web ' +
          'Portal itself. We are all aware of how frequent app switching can be ' +
          'jarring and reduce productivity.'
      },
      {
        title: 'Modify Mission Geometry on DMC Pilot',
        description:
          'On-field situations may sometime require the pilot to modify the mission ' +
          'geometry on the spot. This was previously prohibited to prevent unauthorised ' +
          'changes to the mission. This restriction has been lifted. In future updates, ' +
          'the changes made by the pilot would be highlighted on the DMC Portal for ' +
          'verification.'
      },
      {
        title: 'iOS 14 Support',
        description:
          'DMC Pilot has been updated to support the latest mobile operating ' +
          'system iOS 14 by Apple. Be sure to grab this release before using iOS 14 ' +
          'to execute drone flights.'
      }
    ],
    improvements: [
      {
        title: 'Performance Boost',
        description:
          '<ul>' +
          '<li>Switching between the AOI and the overview mode in a project ' +
          'with large number of features is much faster and feels instant. Blink ' +
          'and you will miss it.</li>' +
          '<li>Opening a project with large number of features has been sped up ' +
          'quite a bit. More optimizations to follow in the upcoming updates.</li>' +
          '</ul>'
      }
    ],
    bugs: [
      {
        title: 'DMC Pilot Mission Selection Crash',
        description:
          'We detected and fixed a crash when selecting a mission under certain ' +
          'conditions. This bug sneaked past us. Sorry about that!'
      },
      {
        title: 'DMC Web Portal Showing Stale Waypoints',
        description:
          'When drawing large missions rapidly, the DMC Web Portal would at times ' +
          'show waypoints of the older mission geometry instead of the most recent ' +
          'one. This strange bug has been put to rest.'
      },
      {
        title: 'PPK Data Copy Workflow Bug',
        description:
          'The DMC Verification App had trouble copying PPK data from the MicroSD ' +
          'card due to a trivial folder name conflict issue.'
      }
    ],
    faqs: []
  },
  {
    version: '2020.9',
    caption: 'Mission Planning on Steroids',
    product: 'Web Portal, Pilot & Verification App',
    date: '2020-09-03',
    features: [
      {
        title: 'Corridor Video Auto-Mission Planning, Execution & Verification',
        description:
          'Video mission planning for corridors like roadways, railways etc. are ' +
          'now supported. And it gets even better. They can be <i>autogenerated</i>! ' +
          'No manual drawing necessary! Import your linestring aoi, set parameters ' +
          "and click generate. That's it. From there, you can execute it on DMC Pilot " +
          'and verify the data using the DMC Verification App.'
      },
      {
        title: 'AOI Based Mission Planning Workflow',
        description:
          'Mission planners now have more control and flexibility by being able ' +
          'to set the GSD, camera model and elevation source on an aoi level. ' +
          'Let that sink in for a moment. Think of each aoi as a mini-project.' +
          'You can plan differently for each of them.'
      },
      {
        title: 'Mining Project Mission Planning, Execution & Verification',
        description:
          'Mines undergo rapid transformation of terrain and hence require more ' +
          'recent elevation profiles for accurate mission planning. This is now ' +
          'supported through the upload of custom spot level (DEM) data. Once done, ' +
          'execute and verify them as usual like any other project.'
      },
      {
        title: 'Experimental Support for DJI Enterprise Matrice 200 Series',
        description:
          'The Zenmuse X4S and X5S cameras can now be used for mission planning ' +
          'and execution. Do note that this is considered experimental. Here be ' +
          'dragons. We would love to hear your feedback when you try this.'
      }
    ],
    improvements: [
      {
        title: 'Detailed Mission Progress Visualization',
        description:
          'Until now, DMC Pilot believed in only 1s and 0s and so the progress ' +
          'of a mission was either complete or incomplete. This has been improved ' +
          'to show detailed progress on a waypoint level. Pilots will start to see ' +
          'the exact portion of a mission that was flown.'
      },
      {
        title: 'Improve Mission Characteristics Accuracy',
        description:
          'Due to a geometric error, the estimates for mission characteristics such ' +
          'as photos, flying time and batteries were off by a small amount. We are ' +
          'talking in the order of a minute. Nevertheless, this has been corrected.'
      }
    ],
    bugs: [
      {
        title: 'Resuming From Last Known Drone Position Breaks DJI Constraint',
        description:
          'DJI waypoints have a constraint where 2 consecutive waypoints cannot be ' +
          'closer than 0.5m. This condition was at times breached on inserting the ' +
          'last known drone position as a new waypoint when resuming a mission. ' +
          'What are the odds of that happening?'
      },
      {
        title: 'Project Location Occasionally Reset',
        description:
          'DMC Web Portal mistakenly reset the project location when a new aoi ' +
          'was uploaded or an existing aoi modified. Sorry about that.'
      },
      {
        title: 'Stitching Failure Not Hidden in Certain Conditions',
        description:
          'The check for stitching failures is done on every vertex added to the ' +
          'mission geometry or on a parameter change. When a stitching failure is ' +
          'shown, it was not being hidden at times when the user took a corrective ' +
          'action. This bug has been put to rest.'
      },
      {
        title: 'Mission Planning Broken Due to Zero Drone Speed',
        description:
          'The maximum drone speed of a mission is constrained by the front ' +
          'overlap, GSD and camera hardware restrictions. In certain combinations ' +
          'of these parameters, the calculated drone speed was rounded off to zero ' +
          'which is grossly wrong. How could you fly a mission by hovering still? ' +
          'We took care of this by fixing the rounding off error.'
      }
    ],
    faqs: []
  },
  {
    version: '2020.8 - Pilot',
    caption: 'DMC Pilot Takes On The iPhone',
    product: 'iOS Pilot App',
    date: '2020-08-01',
    features: [
      {
        title: 'iPhone Support',
        description:
          'DMC Pilot is now responsive and runs beautifully on the iPhone. This is ' +
          'in addition to the iPad Pro, iPad Mini and other Apple devices. Sorry, ' +
          'the Apple Watch is not supported <i>yet</i>.'
      },
      {
        title: 'Cross Grid Mission Planning & Execution',
        description:
          'Plan and execute cross grid missions in a completely offline ' +
          'environment. This mission mode helps enhance the map quality and ' +
          'generate a 3D model.'
      },
      {
        title: 'Resume Missions From Last Known Drone Position',
        description:
          'Missions were previously resumed from the last completed checkpoint ' +
          "(waypoint) like in a game. DMC Pilot's memory has been upgraded to " +
          'remember the exact drone position whenever a mission is interrupted. ' +
          'This position is then used on resuming the mission.'
      }
    ],
    improvements: [],
    bugs: [],
    faqs: []
  },
  {
    version: '2020.8 - Web Portal',
    caption: 'Project management has never been this easy!',
    product: 'Web Portal',
    date: '2020-07-24',
    features: [
      {
        title: 'Revamped Project & Unit Creation Workflows',
        description:
          'The project & unit creation workflows have been redesigned from ' +
          'scratch to be a lot less intimidating. You can create a project in ' +
          'flat 6 clicks. Also, they also look gorgeous! ' +
          "Don't believe us? Try it out yourself now."
      },
      {
        title: 'Cross Grid Mission Planning',
        description:
          'Cross grid missions help enhance the map quality and generate a ' +
          'basic 3D model from the captured data. Try it out from the advanced ' +
          'mission parameters tab.'
      }
    ],
    improvements: [
      {
        title: 'Performance Boost - Speed Thrills',
        description:
          '<ul>' +
          '<li>The map draw performance of the project details page has seen a ' +
          'miracle. It loads 200+ AOIs in a giffy without sweating it.</li>' +
          '<li>We have made the initial load of the web portal faster by ' +
          'reducing the data being downloaded by 56%.</li>' +
          '<li>The areas of interest list in the upload dialog and the ' +
          'project planner page now render buttons faster on hover. No more ' +
          'sloppy lag.</li>' +
          '</ul>'
      },
      {
        title: 'Precise Slider Controls',
        description:
          'Did you find it hard to change the drone speed or the overlap ' +
          'using the slider? We heard you. All sliders in the project planner ' +
          "page now come with +/- button for finer control. You're welcome."
      },
      {
        title: 'View AOI Details Easily',
        description:
          'Navigate the map and click on any area of interest that you find ' +
          'interesting to view its details. Not sure why it took us this ' +
          'long to implement it.'
      },
      {
        title: 'Toggleable Project & Unit Statuses',
        description:
          'The project and unit statuses were previously a one-way switch. They ' +
          'could be clicked to mark a project as complete. You could not reverse ' +
          'it if you set it by mistake. Now you can.'
      }
    ],
    bugs: [
      {
        title: 'Satellite Tile Layer Button State Mismatch',
        description:
          'The satellite tile layer button was incorrectly enabled on ' +
          'opening the project planner a second time.'
      },
      {
        title: 'Inaccurate Unit Progress Calculation',
        description:
          'Unit progress calculation did not account for flight overlaps and the ' +
          'errors introduced due to the coordinate transformation (square degrees ' +
          'to square meters). Both have been nipped in its bud.'
      },
      {
        title: 'AOI/Mission Details Layout Misalignment',
        description:
          'Sometimes the mission/aoi used to collide with the other elements. ' +
          'This sneaky bug has been finally put to rest.'
      }
    ],
    faqs: []
  }
]
