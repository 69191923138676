// Copyright (C) 2024 Skylark Drones

export const PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY
export const PUSHER_CLUSTER = process.env.VUE_APP_PUSHER_CLUSTER

export const RTC_EVENTS = {
  // Drone State
  droneState: 'client-drone-state',
  // Camera State
  droneCameraCommand: 'client-drone-camera-command',
  droneCameraState: 'client-drone-camera-state',
  // Weather State
  weatherState: 'client-weather-state',
  getWeatherState: 'client-get-weather'
}
