// Copyright (C) 2023-2024 Skylark Drones

import { LATEST_DMO_RELEASE } from '@/constants'
import * as Sentry from '@sentry/browser'

class Analytics {
  isLogin = false
  appVersion = LATEST_DMO_RELEASE
  platform = 'web'

  /**
   * Get Event Name from components
   * @param {String|Array<String>} components Components from which to form event name
   * @returns {String} Event name
   */
  getEventName(components) {
    if (typeof components === 'string') {
      return components
    }

    return components.join(' ')
  }

  // This method should initialize the tracking instance
  // with your project token and enable track page views
  initializeTracking() {
    Sentry.captureException(
      new Error('Analytics.initializeTracking method not implemented')
    )
  }

  // This method should set a unique user identifier and associated
  // user information in Mixpanel
  setUserIdentifier(userInfo, brand) {
    Sentry.captureException(
      new Error('Analytics.setUserIdentifier method not implemented')
    )
  }

  // This method should send custom events with
  // the specified event name and properties.
  track(eventNameComponents, properties) {
    Sentry.captureException(new Error('Analytics.track method not implemented'))
  }

  // This method should track page views with
  // the specified event name and properties.
  trackPageView(eventNameComponents, properties) {
    Sentry.captureException(
      new Error('Analytics.trackPageView method not implemented')
    )
  }

  // This method should reset the tracking instance
  // when the user logs out.
  reset() {
    Sentry.captureException(new Error('Analytics.reset method not implemented'))
  }

  /**
   * Tracks user login event.
   *
   * @param {String} userInfo User info for current user.
   * @param {String} loginSource Source where the event originated from.
   */
  trackLogin(userInfo, loginSource) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackLogin method not implemented')
    )
  }

  /**
   * Tracks user sign up event.
   *
   * @param {String} userInfo User info for current user.
   * @param {String} loginSource Source where the event originated from.
   */
  trackSignup(userInfo, loginSource) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSignup method not implemented')
    )
  }

  /**
   * Tracks user logout event.
   */
  trackLogout() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackLogout method not implemented')
    )
  }

  /**
   * Tracks error shown to user.
   *
   * @param {string} source Where the error originated from.
   * @param {ErrorType} errorType Type of the error.
   * @param {string} errorMessage Error message shown to the user.
   */
  trackError(source, errorType, errorMessage = null) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackError method not implemented')
    )
  }

  /**
   * Tracks when a stream is joined.
   *
   * @param {boolean} isPublic true if the stream is public.
   */
  trackStreamJoined(isPublic) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackStreamJoined method not implemented')
    )
  }

  /**
   * Tracks when a stream end.
   *
   * @param {boolean} isPublic true if the stream is public.
   */
  trackStreamEnded(isPublic) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackStreamEnded method not implemented')
    )
  }

  /**
   * Tracks when stream audio is toggled.
   *
   * @param {boolean} isEnabled true when audio broadcasting is started
   */
  trackStreamAudioToggle(isEnabled) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackStreamAudioToggle method not implemented'
      )
    )
  }

  /**
   * Tracks when a permission granted or denied.
   *
   * @param {boolean} isGranted true if permission is granted.
   * @param {Permission} permission Permission requested.
   * @param {PermissionSource} source Source of the permission.
   */
  trackPermission(isGranted, permission, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackPermission method not implemented')
    )
  }

  // region Site

  /**
   * Tracks a site view.
   *
   * @param {Site} site Site that was viewed.
   * @param {Page} source Origin of the event.
   */
  trackSiteView(site, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteView method not implemented')
    )
  }

  /**
   * Track site overview page open.
   */
  trackPageSiteOverView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageSiteOverView method not implemented'
      )
    )
  }

  /**
   * Track collection view.
   */
  trackPageCollectionOverView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageCollectionOverView method not implemented'
      )
    )
  }

  /**
   * Track collection creation view.
   */
  trackPageCollectionCreationView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageCollectionCreationView method not implemented'
      )
    )
  }

  /**
   * Track collection add sites view.
   */
  trackPageCollectionAddSitesView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageCollectionAddSitesView method not implemented'
      )
    )
  }

  /**
   * Track collection add sites view.
   */
  trackPageCollectionAdditionView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageCollectionAdditionView method not implemented'
      )
    )
  }

  /**
   * Track collection delete confirmation view.
   */
  trackPageCollectionDeleteConfirmationView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageCollectionDeleteConfirmationView method not implemented'
      )
    )
  }

  /**
   * Track collection update view.
   */
  trackPageCollectionUpdateView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageCollectionUpdateView method not implemented'
      )
    )
  }

  /**
   * Track walkthrough view.
   */
  trackWalkthroughView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackWalkthroughView method not implemented'
      )
    )
  }

  /**
   * Track home view.
   */
  trackHomeView() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackHomeView method not implemented')
    )
  }

  /**
   * Track settings view.
   */
  trackSettingsView() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSettingsView method not implemented')
    )
  }

  /**
   * Track feet view.
   *
   * @param {'Drone' | 'Battery'} fleetType type of fleet object
   */
  trackFleetsView(fleetType) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackFleetsView method not implemented')
    )
  }

  /**
   * Track mission bulk delete confirmation view.
   */
  trackMissionBulkDeleteConfirmationView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackMissionBulkDeleteConfirmationView method not implemented'
      )
    )
  }

  /**
   * Tracks selection of site type.
   *
   * @param {keyof SiteType} type Selected site type.
   */
  trackSiteTypeSelect(type) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteTypeSelect method not implemented')
    )
  }

  /**
   * Tracks selection of site type view.
   */
  trackSiteTypeSelectionView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteTypeSelectionView method not implemented'
      )
    )
  }

  /**
   * Tracks an event when site location is confirmed.
   */
  trackSiteConfirmLocation() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteConfirmLocation method not implemented'
      )
    )
  }

  /**
   * Tracks when site is created.
   *
   * @param siteDetails Site that was created.
   */
  trackSiteCreate(siteDetails) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteCreate method not implemented')
    )
  }

  /**
   * Tracks selection of mission type.
   *
   * @param {keyof MissionType} missionType Selected mission type.
   */
  trackMissionTypeSelect(missionType) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackMissionTypeSelect method not implemented'
      )
    )
  }

  /**
   * Tracks selection of mission type view.
   */
  trackMissionTypeSelectView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackMissionTypeSelectView method not implemented'
      )
    )
  }

  /**
   * Tracks site refresh.
   *
   * @param hasMissions true if site has missions generated.
   */
  trackSiteRefresh(hasMissions) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteRefresh method not implemented')
    )
  }

  /**
   * Tracks when site was shared.
   *
   * @param siteDetails Site which was shared.
   * @param {Boolean} isNewUser Site was shared with a new user or existing user.
   * @param {String} source From which place site is shared
   */
  trackSiteShare(siteDetails, isNewUser, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteShare method not implemented')
    )
  }

  /**
   * Tracks when a user opens the site share dialog
   * @param {String} source From which place access level is updated
   */
  trackSiteShareSelectionView(source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteShareSelectionView method not implemented'
      )
    )
  }

  /**
   * Tracks when the site access level of a user is changed
   * @param {String} currentAccessLevel Access level user is currently have
   * @param {String} newAccessLevel Access level of user the user updated to
   * @param {String} source From which place access level is updated
   */
  trackSiteSharePermissionUpdate(currentAccessLevel, newAccessLevel, source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteSharePermissionUpdate method not implemented'
      )
    )
  }

  /**
   * Track site upload invalid kml warning view
   */
  trackSiteUploadInvalidWarningView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteUploadInvalidWarningView method not implemented'
      )
    )
  }

  /**
   * Track site upload multi feature warning view
   */
  trackSiteUploadMultiFeatureWarningView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteUploadMultiFeatureWarningView method not implemented'
      )
    )
  }

  /**
   * Tracks when site access was revoked.
   *
   * @param siteDetails Site which was revoked.
   * @param {Boolean} isGuest Site access level is revoked for a guest collaborator.
   * @param {String} source From which place action is performed
   */
  trackSiteRevoke(siteDetails, isGuest, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteRevoke method not implemented')
    )
  }

  /**
   * Tracks when user leaves site.
   *
   * @param siteDetails Site which was left.
   */
  trackSiteLeave(siteDetails) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteLeave method not implemented')
    )
  }

  /**
   * Track site export
   *
   * @param {keyof ExportFormat} format File type in which site is exported
   * @param {String} source From which place site is exported
   */
  trackSiteExport(format, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteExport method not implemented')
    )
  }

  /**
   * Tracks successful deletion of site.
   *
   * @param type Site type.
   * @param size Size of the site.
   * @param source Page from where action is performed.
   */
  trackSiteDelete(type, size, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteDelete method not implemented')
    )
  }

  /**
   * Track site draw
   */
  trackSiteDraw() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteDraw method not implemented')
    )
  }

  /**
   * Track site upload
   */
  trackSiteUpload() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteUpload method not implemented')
    )
  }

  /**
   * Tracks site deletion view.
   */
  trackSiteDeletionView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteDeletionView method not implemented'
      )
    )
  }

  /**
   * Tracks successful edition of site.
   *
   * @param siteDetails Site which was edited.
   */
  trackSiteUpdate(siteDetails) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSiteUpdate method not implemented')
    )
  }

  trackSiteLocationSearch() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteLocationSearch method not implemented'
      )
    )
  }

  trackSiteLocationSearchView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSiteLocationSearchView method not implemented'
      )
    )
  }

  /**
   * Tracks when the user changes the view mode of sites i.e switch between grid and column view
   * @param {String} viewMode sites view mode user selected
   */
  trackSitesViewModeUpdate(viewMode) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSitesViewModeUpdate method not implemented'
      )
    )
  }

  /**
   * Tracks when the user applies site grouping
   * @param {String} groupType sites group type user selected
   */
  trackSitesGroup(groupType) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackSitesGroup method not implemented')
    )
  }

  /**
   * Track the filters applied by the user on sites and collections.
   * @param {Object} filtersInfo
   * @param {Number} filtersInfo.createdByFilterCount
   * @param {Number} filtersInfo.flownByFilterCount
   * @param {String | Array<String} filtersInfo.createdBetweenFilter
   */
  trackSitesAndCollectionsFilter(filtersInfo) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSitesCollectionsFilter method not implemented'
      )
    )
  }

  /**
   * Track the basic and focused searches performed by the user on sites and collections
   * @param {String} searchString query user made for searching sites and collections
   */
  trackSitesAndCollectionsSearch(searchString) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSitesAndCollectionsSearch method not implemented'
      )
    )
  }

  // endregion

  // region Mission

  /**
   * Tracks opening of mission previews dialogs.
   *
   * @param { keyof SiteType } siteType Type of the site.
   * @param { keyof MissionType } missionType Type of mission.
   */
  trackMissionPreview(siteType, missionType) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMissionPreview method not implemented')
    )
  }

  /**
   * Tracks successful mission generation.
   *
   * @param { keyof SiteType} siteType Type of the site..
   * @param { keyof MissionType} missionType Type of mission .
   * @param { Boolean } [isAutogenerated=true] Are missions autogenerated .
   */
  trackMissionCreate(siteType, missionType, isAutogenerated) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMissionCreate method not implemented')
    )
  }

  /**
   * Tracks mission upload.
   *
   * @param {keyof ExportFormat} format File format missions are imported from
   */
  trackMissionUpload(format) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMissionUpload method not implemented')
    )
  }

  /**
   * Tracks mission export.
   *
   * @param {keyof ExportFormat} exportFormat File format missions are exported as
   * @param {String} source From where mission is exported
   * @param {keyof ExportDestination | null} [destination = null] destination 3rd party app to export to
   */
  trackMissionExport(exportFormat, source, destination) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMissionExport method not implemented')
    )
  }

  /**
   * Tracks mission draw.
   */
  trackMissionDraw() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMissionDraw method not implemented')
    )
  }

  /**
   * Track mission Select
   */
  trackMissionSelect() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMissionSelect method not implemented')
    )
  }

  /**
   * Tracks successful bulk mission delete.
   *
   */
  trackBulkMissionDelete() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackBulkMissionDelete method not implemented'
      )
    )
  }

  /**
   * Tracks successful mission delete.
   *
   * @param {String} source Frow where user perform the action
   */
  trackMissionDelete(source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMissionDelete method not implemented')
    )
  }

  /**
   * Tracks successful mission update.
   *
   * @param {String} missionType The type of the mission that was about to get edit
   * @param {String} siteType type of the site of the mission
   */
  trackMissionUpdate(missionType, siteType) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMissionUpdate method not implemented')
    )
  }

  /**
   * Tracks if the user enters the edit mission sidebar. This event is triggered when the
   * user clicks on the edit(pencil) icon on the mission sidebar and the edit state is
   * visible where mission parameters can be modified.
   *
   * @param {String} missionType The type of the mission that was about to get edit
   * @param {String} siteType type of the site of the mission
   */
  trackMissionEditView(missionType, siteType) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackMissionEditView method not implemented'
      )
    )
  }

  // endregion

  /**
   * Tracks when map base layer is updated.
   *
   * @param {keyof MapStyles} mapStyle Map style.
   */
  trackMapLayerUpdate(mapStyle) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMapLayerUpdate method not implemented')
    )
  }

  /**
   * Tracks when map layers selection view.
   */
  trackMapLayerSelectionView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackMapLayerSelectionView method not implemented'
      )
    )
  }

  /**
   * Tracks when the user toggles the visibility of the map features
   * such as site, mission, gcp and utm (airspace advisories)
   *
   * @param {keyof mapFeatureType} mapFeatureType Map feature that was toggled on/off by the user
   * @param {Boolean} isToggledOn Map feature is toggled on or off
   */
  trackMapLayerFeaturesUpdate(mapFeatureType, isToggledOn) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackMapLayerUpdate method not implemented')
    )
  }

  // endregion

  // region Collection

  /**
   * Tracks successful creation of collection.
   *
   * @param sitesCount Number of sites added to collection.
   */
  trackCollectionCreate(sitesCount) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackCollectionCreate method not implemented'
      )
    )
  }

  /**
   * Tracks adding sites to a collection.
   *
   * @param sitesCount Number of sites added to collection.
   */
  trackCollectionAddSites(sitesCount) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackCollectionAddSites method not implemented'
      )
    )
  }

  /**
   * Tracks viewing a collection.
   *
   * @param sitesCount Number of sites added to collection.
   */
  trackCollectionView(sitesCount) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackCollectionView method not implemented')
    )
  }

  /**
   * Track successful deletion of collection
   */
  trackCollectionDelete() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackCollectionDelete method not implemented'
      )
    )
  }

  /**
   * Track successful collection update
   */
  trackCollectionUpdate() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackCollectionUpdate method not implemented'
      )
    )
  }

  /**
   * Track successful bulk delete of gcp
   */
  trackGCPBulkDelete() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackGCPBulkDelete method not implemented')
    )
  }

  /**
   * Track gcp export
   *
   * @param {keyof ExportFormat} format File format gcp exported as
   * @param {String} source Frow where user perform the action
   */
  trackGCPExport(format, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackGCPExport method not implemented')
    )
  }

  /**
   * Track gcp import
   *
   * @param {keyof ExportFormat} format File format gcp exported as
   */
  trackGCPUpload(format) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackGCPUpload method not implemented')
    )
  }

  /**
   * Track gcp planning
   *
   */
  trackGCPPlan() {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackGCPPlan method not implemented')
    )
  }

  /**
   * Tracks gcp autogeneration
   *
   * @param {String} spacing Spacing of the gcp
   * @param {String} corridorWidth Width of the corridor site
   */
  trackGCPCreate(spacing, corridorWidth) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackGCPAutogenerate method not implemented'
      )
    )
  }

  // endregion

  // region Organization

  /**
   * Track when the user (Owner) updates the organization details (branding)
   *
   *  @param {Number} membersCount Total number of active users of org
   */
  trackOrganizationUpdate(membersCount) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationUpdate method not implemented'
      )
    )
  }

  /**
   * Tracks when the user creates a new organization
   */
  trackOrganizationCreate() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationCreate method not implemented'
      )
    )
  }

  /**
   * Track when the user explicitly switches organization through the organization switcher menu.
   *
   * @param {Boolean} isPersonalOrg Org user has switch to is his personal org
   */
  trackOrganizationSwitch(isPersonalOrg) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationSwitch method not implemented'
      )
    )
  }

  /**
   * Tracks when the user removes the access of a guest for all sites
   * effectively removing them from the organization.
   *
   * @param {keyof userRole} userRole Role of user who removed the guest
   */
  trackOrganizationGuestRemove(userRole) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationGuestRemove method not implemented'
      )
    )
  }

  /**
   * Tracks when the user sorts the members table.
   *
   * @param {keyof sortOrder} sortOrder in which direction data is sorted
   * @param {keyof orgMemberSortType} sortType For which type/column data is sorted
   */
  trackOrganizationMemberSort(sortOrder, sortType) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationMemberSort method not implemented'
      )
    )
  }

  /**
   * Tracks when a user is removed from the organization by its admin/owner
   *
   * @param {keyof userRole} userRole Role of the user who removed the member
   */
  trackOrganizationMemberRemove(userRole) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationMemberRemove method not implemented'
      )
    )
  }

  /**
   * Tracks when the user leaves an organization himself
   */
  trackOrganizationMemberLeave() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationMemberLeave method not implemented'
      )
    )
  }

  /**
   * Tracks when the role of an org. member is changed.
   *
   * @param {keyof userRole} userRole Role of user who changed the role of the member
   */
  trackOrganizationMemberRoleUpdate(userRole) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationMemberRoleUpdate method not implemented'
      )
    )
  }

  /**
   * Tracks when a org invite is sent to a user
   *
   * @param {Boolean} isNewUser Was the invited member a new user who does not have a DMO account
   * @param {keyof userRole} invitedUserRole Role assigned to new invited user
   * @param {keyof userRole} userRole Role of user who created the invite
   * @param {Number} orgMembersCount Total no. of active users of org.
   */
  trackOrganizationMemberInvite(
    isNewUser,
    invitedUserRole,
    userRole,
    orgMembersCount
  ) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationMemberInvite method not implemented'
      )
    )
  }

  /**
   * Tracks when a user revoked the org. member invite
   */
  trackOrganizationInviteRevoke() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationInviteRevoke method not implemented'
      )
    )
  }

  /**
   * Tracks when a user declines an org. invite
   */
  trackOrganizationInviteDecline() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationInviteDecline method not implemented'
      )
    )
  }

  /**
   * Tracks when an user accepts an org. invite
   */
  trackOrganizationInviteAccept() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackOrganizationInviteAccept method not implemented'
      )
    )
  }

  // endregion

  // region Miscellaneous

  /**
   * Tracks when the user views the "ReleasePage” page
   *
   * @param {String} source From where user open release page
   */
  trackWhatsNewView(source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackWhatsNewView method not implemented')
    )
  }

  /**
   * Tracks when a user profile is viewed
   *
   * @param {String} source From where user open user profile page
   */
  trackUserProfileView(source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackUserProfileView method not implemented'
      )
    )
  }

  /**
   * Track stream view.
   *
   * @param {Boolean} isPublic Is stream public
   * @param {String} source From where user navigated to the stream view page
   */
  trackPageStreamView(isPublic, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackPageStreamView method not implemented')
    )
  }

  /**
   * Track stream overview page view.
   */
  trackPageStreamOverView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageStreamOverView method not implemented'
      )
    )
  }

  /**
   * Track Recording view page view.
   *
   * @param {String} source From where user navigated to the page
   */
  trackPageRecordingView(source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackPageRecordingView method not implemented'
      )
    )
  }

  /**
   * Track stream object annotation view.
   */
  trackStreamObjectAnnotationView() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackStreamObjectAnnotationLegendView method not implemented'
      )
    )
  }

  /**
   * Track stream object annotation toggle.
   *
   * @param {String} annotationClass Annotation class name
   * @param {Boolean} isEnabled State of the annotation class
   *
   */
  trackStreamObjectAnnotationToggle(annotationClass, isEnabled) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackStreamObjectAnnotationToggle method not implemented'
      )
    )
  }

  /**
   * Track stream object detection.
   *
   * @param {Boolean} isEnabled Is object detection is enabled or not
   */
  trackStreamObjectDetection(isEnabled) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackStreamObjectDetection method not implemented'
      )
    )
  }

  // News

  /**
   * Track news opened
   *
   * @param {Object} news News data
   * @param {String} news.id News id
   * @param {String} news.title Title of the news
   * @param {String} news.url Url of the news
   * @param {Boolean} news.isFeatured Is news a featured news
   * @param {String} source Source from where this is opened
   *
   */
  trackNewsView(news, source) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackNewsView method not implemented')
    )
  }

  /**
   * Track new overview page view.
   *
   * @param {Boolean} isViewAll is view all button clicked
   */
  trackNewsListView(isViewAll) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackNewsListView method not implemented')
    )
  }

  // Homepage

  /**
   * Tracks user downloaded DMO installer
   *
   * @param {String} droneModel Model of the drone app supports
   */
  trackDMOInstallerDownload(droneModel) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackDMOInstallerDownload method not implemented'
      )
    )
  }

  /**
   * Tracks when user opens the supported drone details dialog
   *
   * @param {String} droneModel Name of drone model
   * @param {String} source Source from where this dialog is opened
   */
  trackSupportedDroneDetailView(droneModel, source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackSupportedDroneDetailView method not implemented'
      )
    )
  }

  /**
   * Tracks the airspace advisory page view
   *
   * @param {String} source Location from where this page is opened
   */
  trackAirspaceAdvisoryView(source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackAirspaceAdvisoryView method not implemented'
      )
    )
  }

  /**
   * Tracks users engagement with tips of the day
   *
   * @param {'upvote'|'downvote'} voteType type of vote user posted
   * @param {String} id id of the tip
   * @param {String} title title of the tip
   */
  trackTipVote(voteType, id, tittle) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackTipVote method not implemented')
    )
  }

  /**
   * Track site filter added on the homepage site row
   *
   * @param {Boolean} isMySites toggle state of the my site option
   * @param {'-1d'|'-7d'|'-30d'} createdBetweenFilter value of the created between filter
   * @param {'New'|'In Progress'|'Completed'} statusFilter value of the status filter
   */
  trackHomepageSitesFilter(isMySites, createdBetweenFilter, statusFilter) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackHomepageSitesFilter method not implemented'
      )
    )
  }

  /**
   * Track the user click on the next/prev buttons to scroll the sites
   *
   * @param {'next'|'prev'} direction Scroll direction
   */
  trackHomepageSitesScroll(direction) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackHomepageSitesScroll method not implemented'
      )
    )
  }

  // Data verification

  /**
   * Track entering data verification view
   * 
   * @param {String} source Location from data verification view is entered

   */
  trackDataVerificationView(source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackDataVerificationView method not implemented'
      )
    )
  }

  /**
   * Track upload image action for data verification
   */
  trackImageDatasetUpload() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackImageDatasetUpload method not implemented'
      )
    )
  }

  /**
   * Track delete image action in data verification
   */
  trackImageDatasetDelete() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackImageDatasetDelete method not implemented'
      )
    )
  }

  /**
   * Track Delete all images action in data verification
   */
  trackImageDatasetBulkDelete() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackImageDatasetBulkDelete method not implemented'
      )
    )
  }

  /**
   * Track opening of image metadata view
   *
   * @param {Number} step Step from where the action is performed
   * @param {'dataset'|'result'|'map'} source Location from where this is opened
   */
  trackImageMetadataView(step, source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackImageMetadataView method not implemented'
      )
    )
  }

  /**
   * Track data verification execution
   *
   * @param {Number} step Step from where the action is performed
   * @param {Number} imageCount Count of the image dataset
   * @param {String} taskId Data verification task id
   * @param {Object} checksEnabled Object of what all check are enabled
   * @param {Boolean} customCameraModel Is there any custom camera model
   * @param {Boolean} customFocalLength If custom focal length was used for verification
   * @param {Number} missionCount Number of missions to be verified
   */
  trackDataVerificationExecute(
    step,
    imageCount,
    taskId,
    checksEnabled,
    customCameraModel,
    customFocalLength,
    missionCount
  ) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackImageMetadataView method not implemented'
      )
    )
  }

  /**
   * Track data verification result
   *
   * @param {Number} imageCount Count of the image dataset
   * @param {String} taskId Data verification task id
   * @param {'success'|'failure'} status Status for the data verification
   * @param {Number} criticalFailureCount Object of what all check are enabled
   * @param {Number} mediumFailureCount Is there any custom camera model
   */
  trackDataVerificationComplete(
    imageCount,
    taskId,
    status,
    criticalFailureCount,
    mediumFailureCount
  ) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackDataVerificationComplete method not implemented'
      )
    )
  }

  /**
   * Track exporting of verification results
   */
  trackExportVerificationResults() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackExportVerificationResults method not implemented'
      )
    )
  }

  /**
   * Track verification gcp delete
   */
  trackVerificationGCPDelete() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackVerificationGCPDelete method not implemented'
      )
    )
  }

  /**
   * Track verification GCP reposition
   */
  trackVerificationGCPUpdate() {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackVerificationGCPUpdate method not implemented'
      )
    )
  }

  /**
   * Track verification GCP upload
   *
   * @param {String} format Format for the gcp file uploaded
   */
  trackVerificationGCPInvalidUpload(format) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackVerificationGCPInvalidUpload method not implemented'
      )
    )
  }

  /**
   * Tracks the manual verification dialog view
   *
   * @param {String} source Location from where the dialog is opened
   */
  trackManualGCPVerificationDialogView(source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackManualGCPVerificationDialogView method not implemented'
      )
    )
  }

  /**
   * Track manual gcp verification
   *
   * @param {Boolean} isGCPVisible is GCP visible in the image
   */
  trackManualGCPVerification(isGCPVisible) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackManualGCPVerification method not implemented'
      )
    )
  }

  /**
   * Track selection of missions to be verified
   *
   * @param {Boolean} isSelected True if selected, false if unselected
   * @param {String} source Location from where the mission selections are changed
   */
  trackVerificationMissionSelection(isSelected, source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackVerificationMissionSelection method not implemented'
      )
    )
  }

  /**
   * Track bulk selection of missions to be verified
   *
   * @param {Boolean} isSelected True if selected, false if unselected
   * @param {String} source Location from where the mission selections are changed
   */
  trackVerificationMissionBulkSelection(isSelected, source) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackVerificationMissionBulkSelection method not implemented'
      )
    )
  }

  /**
   * Track splitting of missions
   *
   * @param {String} id Unique identifier to help with grouping events
   * @param {String} source Location from where the mission splitting is done
   * @param {String} siteId Site ID for which missions are split
   * @param {Number} siteSize Size of the site for which missions are split
   * @param {Number} missionSize Size of the mission before splitting
   * @param {String} missionSize Type of mission that is split
   */
  trackMissionSplitting(
    id,
    source,
    siteId,
    siteSize,
    missionSize,
    missionType
  ) {
    Sentry.captureException(
      new Error(
        'UserAnalyticEvents.trackMissionSplitting method not implemented'
      )
    )
  }

  /**
   * Track commands executed by livestream participants
   *
   * @param {String} commandType Type of command executed by participant
   * @param {String} commandName Command name executed by the user
   */
  trackStreamCommand(commandType, commandName) {
    Sentry.captureException(
      new Error('UserAnalyticEvents.trackStreamCommand method not implemented')
    )
  }
}

export default Analytics
