// Copyright (C) 2023-2024 Skylark Drones

import mixpanel from 'mixpanel-browser'
import Analytics from './abstraction/analytics'
import {
  DetailedMissionType,
  SiteType,
  ViewType,
  eventAction,
  eventObject,
  eventProperties,
  page,
  dialog,
  ExportFormat
} from './abstraction/eventEnums'
import { MIXPANEL_PROJECT_TOKEN, MIXPANEL_DEBUG } from '@/constants/service'

class MixpanelAnalytics extends Analytics {
  initializeTracking() {
    mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
      debug: MIXPANEL_DEBUG
    })
  }

  setUserIdentifier(userInfo, brand) {
    mixpanel.identify(userInfo.id)
    mixpanel.people.set({
      $name: userInfo.name,
      $email: userInfo.email,
      $company: userInfo.company
    })
    this.isLogin = true
    this.brand = brand
  }

  track(eventNameComponents, properties) {
    mixpanel.track(this.getEventName(eventNameComponents), {
      [eventProperties.PLATFORM]: this.platform,
      [eventProperties.IS_LOGIN]: this.isLogin,
      app_version: this.appVersion,
      [eventProperties.PRODUCT_BRAND]: this.brand,
      ...properties
    })
  }

  trackPageView(eventNameComponents, properties) {
    mixpanel.track_pageview(
      {
        [eventProperties.PLATFORM]: this.platform,
        [eventProperties.IS_LOGIN]: this.isLogin,
        app_version: this.appVersion,
        [eventProperties.PRODUCT_BRAND]: this.brand,
        ...properties
      },
      { event_name: this.getEventName(eventNameComponents) }
    )
  }

  reset() {
    mixpanel.reset()
    this.isLogin = false
  }

  trackLogin(userInfo, loginSource) {
    this.setUserIdentifier(userInfo)
    this.track([eventObject.USER, eventAction.LOGIN], {
      [eventProperties.SOURCE]: loginSource
    })
  }

  trackSignup(userInfo, loginSource) {
    this.setUserIdentifier(userInfo)
    this.track([eventObject.USER, eventAction.SIGNUP], {
      [eventProperties.SOURCE]: loginSource
    })
  }

  trackSignupView() {
    this.trackPageView([dialog.SIGNUP, eventAction.VIEW])
  }

  trackSignupSkip() {
    this.track([eventObject.SIGNUP, eventAction.SKIP])
  }

  trackLogout() {
    this.track([eventObject.USER, eventAction.LOGOUT])
    this.reset()
  }

  trackError(source, errorType, errorMessage = null) {
    const properties = {
      [eventProperties.SOURCE]: source,
      [eventProperties.ERROR_TYPE]: errorType
    }
    if (errorMessage && errorMessage.trim() !== '') {
      properties[eventProperties.ERROR_MESSAGE] = errorMessage
    }
    this.track([eventObject.ERROR, eventAction.VIEW], properties)
  }

  trackStreamJoined(isPublic) {
    this.track([eventObject.STREAM, eventAction.JOIN], {
      [eventProperties.IS_PUBLIC]: isPublic
    })
  }

  trackStreamEnded(isPublic) {
    this.track([eventObject.STREAM, eventAction.END], {
      [eventProperties.IS_PUBLIC]: isPublic
    })
  }

  trackStreamAudioToggle(isEnabled) {
    this.track([eventObject.STREAM_AUDIO, eventAction.TOGGLE], {
      [eventProperties.IS_ENABLE]: isEnabled
    })
  }

  trackPageStreamView(isPublic, source) {
    this.trackPageView([eventObject.STREAM, eventAction.VIEW], {
      [eventProperties.IS_PUBLIC]: isPublic,
      [eventProperties.SOURCE]: source
    })
  }

  trackPageStreamOverView() {
    this.trackPageView([page.StreamOverviewPage, eventAction.VIEW])
  }

  trackPageRecordingView(source) {
    this.trackPageView([page.RecordingPage, eventAction.VIEW], {
      [eventProperties.SOURCE]: source
    })
  }

  trackStreamObjectAnnotationView() {
    this.trackPageView([eventObject.STREAM_OBJECT_ANNOTATION, eventAction.VIEW])
  }

  trackStreamObjectAnnotationToggle(annotationClass, isEnabled) {
    this.track([eventObject.STREAM_OBJECT_ANNOTATION, eventAction.TOGGLE], {
      [eventProperties.ANNOTATION_CLASS]: annotationClass,
      [eventProperties.IS_ENABLE]: isEnabled
    })
  }

  trackStreamObjectDetection(isEnabled) {
    this.track([eventObject.STREAM_OBJECT_DETECTION, eventAction.TOGGLE], {
      [eventProperties.IS_ENABLE]: isEnabled
    })
  }

  trackPermission(isGranted, permission, source) {
    const action = isGranted ? eventAction.GRANTED : eventAction.DENIED
    this.track(eventObject.PERMISSION, action, {
      [eventProperties.NAME]: permission,
      [eventProperties.SOURCE]: source
    })
  }

  trackSiteView(site, source) {
    this.trackPageView([page.SiteDetailsPage, eventAction.VIEW], {
      [eventProperties.SOURCE]: source,
      [eventProperties.TYPE]: site.geometry.type,
      [eventProperties.IS_SAMPLE]: !!site.sample_data
    })
  }

  trackPageSiteOverView() {
    this.trackPageView([page.SitesOverviewPage, eventAction.VIEW])
  }

  trackPageCollectionOverView() {
    this.trackPageView([page.CollectionsOverviewPage, eventAction.VIEW])
  }

  trackPageCollectionCreationView() {
    this.trackPageView([dialog.COLLECTION_CREATION, eventAction.VIEW])
  }

  trackPageCollectionAddSitesView() {
    this.trackPageView([dialog.COLLECTION_ADD_SITES, eventAction.VIEW])
  }

  trackPageCollectionAdditionView() {
    this.trackPageView([dialog.COLLECTION_ADDITION, eventAction.VIEW])
  }

  trackPageCollectionDeleteConfirmationView() {
    this.trackPageView([
      dialog.COLLECTION_DELETE_CONFIRMATION,
      eventAction.VIEW
    ])
  }

  trackPageCollectionUpdateView() {
    this.trackPageView([dialog.COLLECTION_UPDATION, eventAction.VIEW])
  }

  trackWalkthroughView() {
    this.trackPageView([eventObject.WALKTHROUGH, eventAction.VIEW])
  }

  trackHomeView() {
    this.trackPageView([page.HomePage, eventAction.VIEW])
  }

  trackSettingsView() {
    this.trackPageView([dialog.SETTINGS, eventAction.VIEW])
  }

  trackFleetsView(fleetType) {
    this.trackPageView([eventObject.FLEET, eventAction.VIEW], {
      [eventProperties.TYPE]: fleetType
    })
  }

  trackMissionBulkDeleteConfirmationView() {
    this.trackPageView([
      dialog.MISSION_BULK_DELETE_CONFIRMATION,
      eventAction.VIEW
    ])
  }

  trackSiteTypeSelect(type) {
    this.track([eventObject.SITE_TYPE, eventAction.SELECT], {
      [eventProperties.TYPE]: SiteType[type]
    })
  }

  trackSiteTypeSelectionView() {
    this.trackPageView([dialog.SITE_TYPE_SELECT, eventAction.VIEW])
  }

  trackSiteConfirmLocation() {
    this.track([eventObject.SITE_LOCATION, eventAction.CONFIRM])
  }

  trackSiteCreate(siteDetails) {
    this.track([eventObject.SITE, eventAction.CREATE], {
      [eventProperties.TYPE]: siteDetails.geometry.type,
      [eventProperties.SIZE]: siteDetails.geometry.properties?.size,
      [eventProperties.ELEVATION_SOURCE]: siteDetails.elevation_source
    })
  }

  trackMissionTypeSelect(missionType) {
    this.track([eventObject.MISSION_TYPE, eventAction.SELECT], {
      [eventProperties.TYPE]: missionType
    })
  }

  trackMissionTypeSelectView() {
    this.trackPageView([dialog.MISSION_TYPE_SELECT, eventAction.VIEW])
  }

  trackSiteRefresh(hasMissions) {
    this.track([eventObject.SITE, eventAction.REFRESH], {
      [eventProperties.HAS_MISSIONS]: hasMissions
    })
  }

  trackSiteShare({ elevationSource, siteSize, siteType }, isNewUser, source) {
    this.track([eventObject.SITE, eventAction.SHARE], {
      [eventProperties.TYPE]: SiteType[siteType],
      [eventProperties.SIZE]: siteSize,
      [eventProperties.ELEVATION_SOURCE]: elevationSource,
      [eventProperties.SOURCE]: source,
      [eventProperties.IS_NEW_USER]: isNewUser
    })
  }

  trackSiteShareSelectionView(source) {
    this.trackPageView([dialog.SITE_SHARE, eventAction.VIEW], {
      [eventProperties.VIEW_TYPE]: ViewType.DIALOG,
      [eventProperties.SOURCE]: source
    })
  }

  trackSiteUploadInvalidWarningView() {
    this.trackPageView([dialog.SITE_UPLOAD_INVALID_KML, eventAction.VIEW], {
      [eventProperties.VIEW_TYPE]: ViewType.DIALOG
    })
  }

  trackSiteUploadMultiFeatureWarningView() {
    this.trackPageView([dialog.SITE_UPLOAD_MULTI_FEATURE, eventAction.VIEW], {
      [eventProperties.VIEW_TYPE]: ViewType.DIALOG
    })
  }

  trackSiteRevoke({ elevationSource, siteSize, siteType }, isGuest, source) {
    this.track([eventObject.SITE, eventAction.REVOKE], {
      [eventProperties.TYPE]: SiteType[siteType],
      [eventProperties.SIZE]: siteSize,
      [eventProperties.ELEVATION_SOURCE]: elevationSource,
      [eventProperties.SOURCE]: source,
      [eventProperties.IS_GUEST]: isGuest
    })
  }

  trackSiteLeave(siteDetails) {
    this.track([eventObject.SITE, eventAction.LEAVE], {
      [eventProperties.TYPE]: siteDetails.geometry.type,
      [eventProperties.SIZE]: siteDetails.geometry.properties.size,
      [eventProperties.ELEVATION_SOURCE]: siteDetails.elevation_source
    })
  }

  trackSiteExport(format, source) {
    this.track([eventObject.SITE, eventAction.EXPORT], {
      [eventProperties.SITE_FORMAT]: format,
      [eventProperties.SOURCE]: source
    })
  }

  trackSiteDelete(type, size, source) {
    this.track([eventObject.SITE, eventAction.DELETE], {
      [eventProperties.TYPE]: type,
      [eventProperties.SIZE]: size,
      [eventProperties.SOURCE]: source
    })
  }

  trackSiteDraw() {
    this.track([eventObject.SITE, eventAction.DRAW])
  }

  trackSiteUpload() {
    this.track([eventObject.SITE, eventAction.UPLOAD])
  }

  trackSiteDeletionView() {
    this.trackPageView([dialog.SITE_DELETE_CONFIRMATION, eventAction.VIEW])
  }

  trackSiteUpdate(siteDetails) {
    this.track([eventObject.SITE, eventAction.UPDATE], {
      [eventProperties.TYPE]: siteDetails.geometry.type,
      [eventProperties.SIZE]: siteDetails.geometry.properties?.size,
      [eventProperties.ELEVATION_SOURCE]: siteDetails.elevationSource
    })
  }

  trackSiteLocationSearch() {
    this.track([eventObject.SITE_LOCATION, eventAction.SEARCH])
  }

  trackSiteLocationSearchView() {
    this.trackPageView([dialog.SITE_LOCATION_SEARCH, eventAction.VIEW])
  }

  trackSiteSharePermissionUpdate(currentAccessLevel, newAccessLevel, source) {
    this.track(
      [
        eventObject.SITE,
        eventAction.SHARE,
        eventAction.PERMISSION,
        eventAction.UPDATE
      ],
      {
        [eventProperties.OLD_ACCESS_LEVEL]: currentAccessLevel,
        [eventProperties.NEW_ACCESS_LEVEL]: newAccessLevel,
        [eventProperties.SOURCE]: source
      }
    )
  }

  trackSitesViewModeUpdate(viewMode) {
    this.track([eventObject.SITES, eventAction.VIEW_MODE, eventAction.UPDATE], {
      [eventProperties.VIEW_MODE]: viewMode
    })
  }

  trackSitesGroup(groupType) {
    this.track([eventObject.SITES, eventAction.GROUP], {
      [eventProperties.GROUP_TYPE]: groupType
    })
  }

  trackSitesAndCollectionsFilter(filtersInfo) {
    this.track([eventObject.SITES_COLLECTIONS, eventAction.FILTER], {
      [eventProperties.CREATED_BY_FILTER_COUNT]:
        filtersInfo.createdByFilterCount,
      [eventProperties.FLOWN_BY_FILTER_COUNT]: filtersInfo.flownByFilterCount,
      [eventProperties.CREATED_BETWEEN_FILTER]: filtersInfo.createdBetweenFilter
    })
  }

  trackSitesAndCollectionsSearch(searchString) {
    this.track([eventObject.SITES_COLLECTIONS, eventAction.SEARCH], {
      [eventProperties.SEARCH_STRING]: searchString
    })
  }

  trackMissionPreview(siteType, missionType) {
    this.track([dialog.MISSION_PREVIEW, eventAction.VIEW], {
      [eventProperties.VIEW_TYPE]: ViewType.DIALOG,
      [eventProperties.SITE_TYPE]: siteType,
      [eventProperties.MISSION_TYPE]: missionType
    })
  }

  trackMissionCreate(siteType, missionType, isAutogenerated = true) {
    this.track([eventObject.MISSION, eventAction.CREATE], {
      [eventProperties.IS_AUTOGENERATE]: isAutogenerated,
      [eventProperties.MISSION_TYPE]: DetailedMissionType.from(
        SiteType[siteType],
        missionType
      )
    })
  }

  trackMissionUpload(format) {
    this.track([eventObject.MISSION, eventAction.UPLOAD], {
      [eventProperties.MISSION_FORMAT]: format
    })
  }

  trackMissionExport(format, source, destination = null) {
    this.track([eventObject.MISSION, eventAction.EXPORT], {
      [eventProperties.MISSION_FORMAT]: format,
      [eventProperties.SOURCE]: source,
      [eventProperties.MISSION_EXPORT_DESTINATION]: destination
    })
  }

  trackMissionDraw() {
    this.track([eventObject.MISSION, eventAction.DRAW])
  }

  trackMissionSelect() {
    this.track([eventObject.MISSION, eventAction.SELECT])
  }

  trackBulkMissionDelete() {
    this.track([eventObject.MISSION, eventAction.BULK_DELETE])
  }

  trackMissionDelete(source) {
    this.track([eventObject.MISSION, eventAction.DELETE], {
      [eventProperties.SOURCE]: source
    })
  }

  trackMissionUpdate(missionType, siteType, updatedMissionParams) {
    this.track([eventObject.MISSION, eventAction.UPDATE], {
      [eventProperties.MISSION_TYPE]:
        DetailedMissionType[missionType.toUpperCase()],
      [eventProperties.SITE_TYPE]: SiteType[siteType],
      [eventProperties.MISSION_PARAMS]: updatedMissionParams
    })
  }

  trackMissionEditView(missionType, siteType) {
    this.track([eventObject.MISSION, eventAction.EDIT, eventAction.VIEW], {
      [eventProperties.MISSION_TYPE]:
        DetailedMissionType[missionType.toUpperCase()],
      [eventProperties.SITE_TYPE]: SiteType[siteType]
    })
  }

  trackMapLayerUpdate(mapStyle) {
    this.track([eventObject.MAP_LAYER, eventAction.UPDATE], {
      [eventProperties.STYLE]: mapStyle
    })
  }

  trackMapLayerSelectionView() {
    this.trackPageView([dialog.MAP_LAYER_SELECTION, eventAction.VIEW])
  }

  trackMapLayerFeaturesUpdate(mapFeatureType, isToggledOn) {
    this.track(
      [eventObject.MAP_LAYER, eventAction.FEATURES, eventAction.UPDATE],
      {
        [eventProperties.MAP_FEATURE_TYPE]: mapFeatureType,
        [eventProperties.STATUS]: isToggledOn
      }
    )
  }

  trackCollectionCreate(sitesCount) {
    this.track([eventObject.COLLECTION, eventAction.CREATE], {
      [eventProperties.SITES_COUNT]: sitesCount
    })
  }

  trackCollectionAddSites(sitesCount) {
    this.track([eventObject.COLLECTION, eventAction.ADD_SITES], {
      [eventProperties.SITES_COUNT]: sitesCount
    })
  }

  trackCollectionView(sitesCount) {
    this.track([eventObject.COLLECTION, eventAction.VIEW], {
      [eventProperties.SITES_COUNT]: sitesCount
    })
  }

  trackCollectionDelete() {
    this.track([eventObject.COLLECTION, eventAction.DELETE])
  }

  trackCollectionUpdate() {
    this.track([eventObject.COLLECTION, eventAction.UPDATE])
  }

  trackGCPBulkDelete() {
    this.track([eventObject.GCP, eventAction.BULK_DELETE])
  }

  trackGCPExport(format, source) {
    this.track([eventObject.GCP, eventAction.EXPORT], {
      [eventProperties.GCP_FORMAT]: ExportFormat[format],
      [eventProperties.SOURCE]: source
    })
  }

  trackGCPUpload(format) {
    this.track([eventObject.GCP, eventAction.UPLOAD], {
      [eventProperties.GCP_FORMAT]: format
    })
  }

  trackGCPPlan() {
    this.track([eventObject.GCP, eventAction.PLAN])
  }

  trackGCPCreate(spacing, corridorWidth) {
    this.track([eventObject.GCP, eventAction.CREATE], {
      [eventProperties.SPACING]: spacing,
      [eventProperties.CORRIDOR_WIDTH]: corridorWidth,
      [eventProperties.IS_AUTOGENERATE]: true
    })
  }

  trackOrganizationUpdate(membersCount) {
    this.track([eventObject.ORGANIZATION, eventAction.UPDATE], {
      [eventProperties.ORG_MEMBERS_COUNT]: membersCount
    })
  }

  trackOrganizationCreate() {
    this.track([eventObject.ORGANIZATION, eventAction.CREATE])
  }

  trackOrganizationSwitch(isPersonalOrg) {
    this.track([eventObject.ORGANIZATION, eventAction.SWITCH], {
      [eventProperties.IS_PERSONAL_ORG]: isPersonalOrg
    })
  }

  trackOrganizationGuestRemove(userRole) {
    this.track(
      [eventObject.ORGANIZATION, eventObject.GUEST, eventAction.REMOVE],
      { [eventProperties.SELF_ROLE]: userRole }
    )
  }

  trackOrganizationMemberSort(sortOrder, sortType) {
    this.track(
      [eventObject.ORGANIZATION, eventObject.MEMBER, eventAction.SORT],
      {
        [eventProperties.SORT_ORDER]: sortOrder,
        [eventProperties.SORT_TYPE]: sortType
      }
    )
  }

  trackOrganizationMemberRemove(userRole) {
    this.track(
      [eventObject.ORGANIZATION, eventObject.MEMBER, eventAction.REMOVE],
      { [eventProperties.SELF_ROLE]: userRole }
    )
  }

  trackOrganizationMemberLeave() {
    this.track([
      eventObject.ORGANIZATION,
      eventObject.MEMBER,
      eventAction.LEAVE
    ])
  }

  trackOrganizationMemberRoleUpdate(userRole) {
    this.track(
      [
        eventObject.ORGANIZATION,
        eventObject.MEMBER,
        eventObject.ROLE,
        eventAction.UPDATE
      ],
      { [eventProperties.SELF_ROLE]: userRole }
    )
  }

  trackOrganizationMemberInvite(
    isNewUser,
    invitedUserRole,
    userRole,
    orgMembersCount
  ) {
    this.track(
      [eventObject.ORGANIZATION, eventObject.MEMBER, eventObject.INVITE],
      {
        [eventProperties.IS_NEW_USER]: isNewUser,
        [eventProperties.ROLE]: invitedUserRole,
        [eventProperties.SELF_ROLE]: userRole,
        [eventProperties.ORG_MEMBERS_COUNT]: orgMembersCount
      }
    )
  }

  trackOrganizationInviteRevoke() {
    this.track([
      eventObject.ORGANIZATION,
      eventObject.INVITE,
      eventAction.REVOKE
    ])
  }

  trackOrganizationInviteDecline() {
    this.track([
      eventObject.ORGANIZATION,
      eventObject.INVITE,
      eventAction.DECLINE
    ])
  }

  trackOrganizationInviteAccept() {
    this.track([
      eventObject.ORGANIZATION,
      eventObject.INVITE,
      eventAction.ACCEPT
    ])
  }

  trackWhatsNewView(source) {
    this.trackPageView([page.ReleasePage, eventAction.VIEW], {
      [eventProperties.SOURCE]: source
    })
  }

  trackUserProfileView(source) {
    this.trackPageView([page.UsersDisplay, eventAction.VIEW], {
      [eventProperties.SOURCE]: source
    })
  }

  trackNewsView(news, source) {
    this.trackPageView([dialog.NEWS, eventAction.VIEW], {
      [eventProperties.ID]: news.id,
      [eventProperties.TITLE]: news.title,
      [eventProperties.URL]: news.url,
      [eventProperties.IS_FEATURED]: news.isFeatured,
      [eventProperties.SOURCE]: source
    })
  }

  trackNewsListView(isViewAll) {
    this.trackPageView([eventObject.NEWS_LIST, eventAction.VIEW], {
      [eventProperties.IS_VIEW_ALL]: isViewAll
    })
  }

  trackDMOInstallerDownload(droneModel) {
    this.track([eventObject.DMO_INSTALLER, eventAction.DOWNLOAD], {
      [eventProperties.DRONE_MODEL]: droneModel
    })
  }

  trackSupportedDroneDetailView(droneModel, source) {
    this.trackPageView([eventObject.SUPPORTED_DRONE_DETAIL, eventAction.VIEW], {
      [eventProperties.DRONE_MODEL]: droneModel,
      [eventProperties.SOURCE]: source
    })
  }

  trackAirspaceAdvisoryView(source) {
    this.trackPageView([eventObject.AIRSPACE_ADVISORY, eventAction.VIEW], {
      [eventProperties.SOURCE]: source
    })
  }

  trackTipVote(voteType, id, title) {
    this.track([eventObject.TIP, eventAction.VOTE], {
      [eventProperties.VOTE_TYPE]: voteType,
      [eventProperties.ID]: id,
      [eventProperties.TITLE]: title
    })
  }

  trackHomepageSitesFilter(isMySites, createdBetweenFilter, statusFilter) {
    this.track([eventObject.HOMEPAGE, eventObject.SITES, eventAction.FILTER], {
      [eventProperties.IS_MY_SITES]: isMySites,
      [eventProperties.CREATED_BETWEEN_FILTER]: createdBetweenFilter,
      [eventProperties.STATUS_FILTER]: statusFilter
    })
  }

  trackHomepageSitesScroll(direction) {
    this.track([eventObject.HOMEPAGE, eventObject.SITES, eventAction.SCROLL], {
      [eventProperties.DIRECTION]: direction
    })
  }

  trackImageDatasetBulkDelete() {
    this.track([eventObject.IMAGE_METADATA, eventAction.BULK_DELETE])
  }

  trackImageDatasetDelete() {
    this.track([eventObject.IMAGE_METADATA, eventAction.DELETE])
  }

  trackImageDatasetUpload(imageCount) {
    this.track([eventObject.IMAGE_METADATA, eventAction.UPLOAD], {
      [eventProperties.IMAGE_COUNT]: imageCount
    })
  }

  trackImageMetadataView(step, source) {
    this.trackPageView([eventObject.IMAGE_METADATA, eventAction.VIEW], {
      [eventProperties.STEP]: step,
      [eventProperties.SOURCE]: source
    })
  }

  trackDataVerificationView(source) {
    this.track([eventObject.DATA_VERIFICATION, eventAction.VIEW], {
      [eventProperties.SOURCE]: source
    })
  }

  trackDataVerificationExecute(
    step,
    imageCount,
    taskId,
    checksEnabled,
    customCameraModel,
    customFocalLength,
    missionCount
  ) {
    this.track([eventObject.DATA_VERIFICATION, eventAction.EXECUTE], {
      [eventProperties.IMAGE_COUNT]: imageCount,
      [eventProperties.STEP]: step,
      [eventProperties.TASK_ID]: taskId,
      [eventProperties.CHECKS_ENABLED]: checksEnabled,
      [eventProperties.CUSTOM_CAMERA_MODEL]: customCameraModel,
      [eventProperties.CUSTOM_FOCAL_LENGTH]: customFocalLength,
      [eventProperties.MISSION_COUNT]: missionCount
    })
  }

  trackDataVerificationComplete(
    imageCount,
    taskId,
    status,
    criticalFailureCount,
    mediumFailureCount
  ) {
    this.track([eventObject.DATA_VERIFICATION, eventAction.COMPLETE], {
      [eventProperties.IMAGE_COUNT]: imageCount,
      [eventProperties.TASK_ID]: taskId,
      [eventProperties.STATUS]: status,
      [eventProperties.CRITICAL_FAILURE_COUNT]: criticalFailureCount,
      [eventProperties.MEDIUM_FAILURE_COUNT]: mediumFailureCount
    })
  }

  trackExportVerificationResults() {
    this.track([eventObject.DATA_VERIFICATION, eventAction.DOWNLOAD])
  }

  trackVerificationGCPDelete() {
    this.track([eventObject.VERIFICATION_GCP, eventAction.DELETE])
  }

  trackVerificationGCPUpdate() {
    this.track([eventObject.VERIFICATION_GCP, eventAction.UPDATE])
  }

  trackVerificationGCPInvalidUpload(format) {
    this.track(
      [eventObject.VERIFICATION_GCP, eventAction.INVALID, eventAction.UPLOAD],
      {
        [eventProperties.GCP_FORMAT]: format
      }
    )
  }

  trackManualGCPVerificationDialogView(source) {
    this.trackPageView(
      [eventObject.MANUAL_GCP_VERIFICATION, eventAction.VIEW],
      {
        [eventProperties.SOURCE]: source
      }
    )
  }

  trackManualGCPVerification(isGCPVisible) {
    this.track(eventObject.MANUAL_GCP_VERIFICATION, {
      [eventProperties.IS_GCP_VISIBLE]: isGCPVisible
    })
  }

  trackVerificationMissionSelection(isSelected, source) {
    this.track([eventObject.VERIFICATION_MISSION, eventAction.SELECT], {
      [eventProperties.IS_SELECTED]: isSelected,
      [eventProperties.SOURCE]: source
    })
  }

  trackVerificationMissionBulkSelection(isSelected, source) {
    this.track([eventObject.VERIFICATION_MISSION, eventAction.BULK_SELECT], {
      [eventProperties.IS_SELECTED]: isSelected,
      [eventProperties.SOURCE]: source
    })
  }

  trackMissionSplitting(
    id,
    source,
    siteId,
    siteSize,
    missionSize,
    missionType
  ) {
    this.track([eventObject.MISSION, eventAction.SPLIT], {
      [eventProperties.ID]: id,
      [eventProperties.SOURCE]: source,
      [eventProperties.SITE_ID]: siteId,
      [eventProperties.SITE_SIZE]: siteSize,
      [eventProperties.SIZE]: missionSize,
      [eventProperties.MISSION_TYPE]: missionType
    })
  }

  trackStreamCommand(commandType, commandName) {
    this.track([eventObject.STREAM_COMMAND, eventAction.EXECUTE], {
      [eventProperties.TYPE]: commandType,
      [eventProperties.NAME]: commandName
    })
  }
}

export default MixpanelAnalytics
