// Copyright (C) 2024 Skylark Drones

export const FEATURES = {
  STREAM_VIEW_DRONE_BATTERY: 'VIEW_DRONE_BATTERY',
  STREAM_VIEW_RC_BATTERY: 'VIEW_RC_BATTERY',
  STREAM_SWITCH_CAMERA_MODE: 'SWITCH_CAMERA_MODE',
  STREAM_RECORD_VIDEO: 'RECORD_VIDEO',
  STREAM_CAPTURE_PHOTO: 'CAPTURE_PHOTO',
  STREAM_CHANGE_GIMBAL_PITCH: 'CHANGE_GIMBAL_PITCH',
  STREAM_SWITCH_LENS: 'SWITCH_LENS',
  STREAM_LOCK_FOV_CHANGE: 'LOCK_FOV_CHANGE',
  STREAM_LOCK_CAMERA_CONTROL: 'LOCK_CAMERA_CONTROL',
  STREAM_VIEW_DRONE_HMS: 'VIEW_DRONE_HMS',
  STREAM_VIEW_WEATHER: 'VIEW_WEATHER',
  STREAM_VIEW_CAMERA_CONTROL_ERROR: 'VIEW_CAMERA_CONTROL_ERROR'
}

const featureFlags = {
  [FEATURES.STREAM_VIEW_DRONE_BATTERY]: { version: '2024.12' },
  [FEATURES.STREAM_VIEW_RC_BATTERY]: { version: '2024.12' },
  [FEATURES.STREAM_SWITCH_CAMERA_MODE]: { version: '2024.12' },
  [FEATURES.STREAM_RECORD_VIDEO]: { version: '2024.12' },
  [FEATURES.STREAM_CAPTURE_PHOTO]: { version: '2024.12' },
  [FEATURES.STREAM_CHANGE_GIMBAL_PITCH]: { version: '2024.12' },
  [FEATURES.STREAM_SWITCH_LENS]: { version: '2024.12' },
  [FEATURES.STREAM_LOCK_FOV_CHANGE]: { version: '2024.12' },
  [FEATURES.STREAM_LOCK_CAMERA_CONTROL]: { version: '2025.1' },
  [FEATURES.STREAM_VIEW_DRONE_HMS]: { version: '2025.1' },
  [FEATURES.STREAM_VIEW_WEATHER]: { version: '2025.1' },
  [FEATURES.STREAM_VIEW_CAMERA_CONTROL_ERROR]: { version: '2025.1' }
}

/**
 * Utility function to compare semantic versions.
 *
 * @param {string} versionA - The current app version (e.g., '2024.1').
 * @param {string} versionB - The version when the feature was introduced.
 * @returns {boolean} - Returns true if the current version is greater than or equal to the feature version.
 */
export function isVersionNumberGreaterOrEqual(versionA, versionB) {
  // Split intonumeric segments (e.g., '2024.10.1' => [2024, 10, 1])
  const segmentsA = versionA.split('.').map(Number)
  const segmentsB = versionB.split('.').map(Number)

  const maxLength = Math.max(segmentsA.length, segmentsB.length)

  for (let i = 0; i < maxLength; i++) {
    // Use 0 for missing segmnts (e.g., '2024.10' becomes '2024.10.0')
    const segmentA = segmentsA[i] || 0
    const segmentB = segmentsB[i] || 0

    if (segmentA > segmentB) return true
    if (segmentA < segmentB) return false
  }

  // Both versions are equal
  return true
}

/**
 * Checks if a feature is available based on the current app version and variant.
 *
 * @param {string} feature - The feature to check (use the `Features` enum).
 * @returns {boolean} - Returns true if the feature is available in the current version and variant.
 */
export function isFeatureAvailable(currentVersion, feature) {
  const featureConfig = featureFlags[feature]
  if (!featureConfig) return false

  const versionCheck = isVersionNumberGreaterOrEqual(
    currentVersion.split('-')[0],
    featureConfig.version
  )

  return versionCheck
}
