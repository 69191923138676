// Copyright (C) 2024 Skylark Drones

import { FEATURES, isFeatureAvailable } from './featureVersionControl'
const FeatureVersionControlPlugin = {
  install(Vue) {
    Vue.prototype.$isFeatureAvailable = isFeatureAvailable
    Vue.prototype.$FEATURES = FEATURES
  }
}

export default FeatureVersionControlPlugin
